import { Partner } from '@types';

/**
 * Check if a vendor is custom. A custom vendor is not a Didomi vendor and belongs to the current organization.
 *
 * @param vendor - The vendor to check.
 * @param organizationId - The current organization's ID.
 * @returns True if the vendor is custom, false otherwise.
 */
export const isCustomVendor = (vendor: Partner, organizationId: string): boolean => {
  const isDidomiVendor = (vendor: Partner) => typeof vendor.namespaces?.didomi === 'string';
  return !isDidomiVendor(vendor) && vendor.organization_id === organizationId;
};

export const isATPVendor = (vendor: Partner) => Number.isInteger(vendor.namespaces?.google);

export const isIABVendor = (vendor: Partner) => vendor.namespaces?.iab2 > 0;

export const isSelectedVendor = (vendor: Partner, selectedVendorIds: string[]) => selectedVendorIds.some(vendorId => vendor.id === vendorId);
