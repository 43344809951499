import React, { useState } from 'react';
import { DidomiAutocompleteCustomEvent } from '@didomi/ui-atoms';
import {
  DidomiAutocomplete,
  DidomiButton,
  DidomiHintbox,
  DidomiModal,
  DidomiModalContent,
  DidomiModalHeader,
  DidomiSelectOption,
  DidomiSelectOptions,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { Property } from 'types/responses/property';

interface BindPropertiesWithNoticeTemplateModalProps {
  isOpen: boolean;
  properties: Property[];
  selectedPropertyIds: string[];
  noticeTemplateId: string;
  onOptionSelect: (value: string[]) => void;
  onClose: (value: boolean) => void;
  onSubmit: (selectedPropertyIds: string[]) => void;
}

export const BindPropertiesWithNoticeTemplateModal = ({
  isOpen,
  properties,
  selectedPropertyIds,
  noticeTemplateId,
  onOptionSelect,
  onClose,
  onSubmit,
}: BindPropertiesWithNoticeTemplateModalProps) => {
  const [filteredProperties, setFilteredProperties] = useState<Property[]>(properties);

  const handleDialogChange = (e: CustomEvent) => {
    if (isOpen && !e.detail) {
      onClose(e.detail);
    }
  };

  const handleSearchChange = async (e: CustomEvent<string>) => {
    const searchQuery = e.detail.toLowerCase().trim();
    /* istanbul ignore next */
    if (!searchQuery) {
      setFilteredProperties(properties);
    }
    /* istanbul ignore next */
    const filteredProperties = properties.filter(property => property.name.toLowerCase().includes(searchQuery));
    setFilteredProperties(filteredProperties);
  };

  const propertyNamesBelongingToNoticeTemplate = properties.filter((property: Property) => property.notice_template_id === noticeTemplateId).map(property => property.name);

  return (
    <div>
      <DidomiModal id="modal" variant="slim" isOpen={isOpen} onOpenChange={handleDialogChange} data-testid="ValidationBindPropertiesWithNoticeTemplateModal" closable>
        <DidomiModalHeader
          modal-sub-title="Attach this vendor template to properties"
          modal-description="You want to apply this vendor template to properties. Select the relevant properties."
        ></DidomiModalHeader>
        <DidomiModalContent>
          {properties && (
            <>
              <DidomiHintbox>
                {propertyNamesBelongingToNoticeTemplate.length
                  ? `This vendor template is attached to the following properties: ${propertyNamesBelongingToNoticeTemplate.join(',')}`
                  : 'This vendor template is not attached to any property yet.'}
              </DidomiHintbox>
              <DidomiAutocomplete
                multi={true}
                data-testid="properties-autocomplete"
                label="Properties"
                placeholder={'Select properties'}
                name="select-properties"
                className={tx('mt-1 mb-4')}
                data-skeleton
                value={selectedPropertyIds}
                onValueChange={(e: DidomiAutocompleteCustomEvent<string[]>) => onOptionSelect(e.detail)}
                onSearchChange={handleSearchChange}
                onAutocompleteClear={() => onOptionSelect([])}
                size="md"
              >
                <DidomiSelectOptions>
                  {filteredProperties &&
                    filteredProperties?.map((property: Property) => (
                      <DidomiSelectOption label={property.name} value={property.id} key={property.id} selectable={true} selected={selectedPropertyIds.includes(property.id)} />
                    ))}
                </DidomiSelectOptions>
              </DidomiAutocomplete>
              <div className="flex flex-col items-center mt-5">
                <DidomiButton size="medium" variant="secondary" className={tx('mb-2.5')} full-width onClick={() => onClose(false)}>
                  Cancel
                </DidomiButton>
                <DidomiButton size="medium" variant="main" full-width onClick={() => onSubmit(selectedPropertyIds)}>
                  Confirm
                </DidomiButton>
              </div>
            </>
          )}
        </DidomiModalContent>
      </DidomiModal>
    </div>
  );
};
