import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFeatureFlag, useSnackbar, useSPARouter } from '@didomi/utility-react';
import { InitialValues, PropertyForm } from '@components';
import { acmScenariosInitData, nonAcmScenariosInitData, NOT_NULL_DEFAULT_PASSWORD, NO_SCHEDULE_SET, DEFAULT_PAGES_COUNT } from '@constants';
import { useEditDomain, useOrganizationQuotas } from '@hooks';
import { useProperty } from '../../hooks/data/properties/useProperty.hook';
import { Property } from '../../types/responses/property';

const getInitialValues = (): InitialValues => ({
  name: '',
  website: '',
  isOwner: true,
  scenarios: [],
  filterValue: '',
  isFilterRegex: false,
  pages_count: DEFAULT_PAGES_COUNT,
  country: 'fr',
  schedule: '0 0 1 * *',
  login: {
    enabled: false,
    selectors: {
      pathToForm: '',
      usernameField: '',
      passwordField: '',
      submitButton: '',
    },
    credentials: {
      username: '',
      password: '',
    },
  },
  filters: [],
});

export const EditDomain = () => {
  const params = useParams();
  const { data: property, isLoading: isLoadingProperty } = useProperty({ id: params.id });
  const { data: quotas, isLoading: isLoadingQuotas } = useOrganizationQuotas();
  const { displaySnackbar } = useSnackbar();
  const [hasComplianceReportPremiumFeature, isLoadingComplianceReportPremiumFeature] = useFeatureFlag('compliance_report_v2');

  const { mutateAsync: editDomain, isLoading: isUpdatingDomain } = useEditDomain();

  const { navigateTo } = useSPARouter();

  const isLoading = isLoadingProperty || isLoadingComplianceReportPremiumFeature || isLoadingQuotas;

  const initialValues = useMemo(() => {
    const values = getInitialValues();
    if (!property || isLoading) return values;

    const initScenarios = hasComplianceReportPremiumFeature ? acmScenariosInitData : nonAcmScenariosInitData;

    const firstFilter = property.filters?.[0];
    const isFilterRegex = firstFilter?.type === 'regexp';

    return {
      ...values,
      ...property,
      // The init scenarios are used as an extra precaution to avoid a use-case where the property does not have the scenarios.
      // For example this might happen if the property is been created in the middle of the data migration.
      scenarios: property.scenarios?.length > 0 ? property.scenarios : initScenarios,
      isFilterRegex,
      schedule: property.schedule === null ? NO_SCHEDULE_SET : property.schedule,
      filterValue: firstFilter?.value || '',
      ...(property?.login?.enabled
        ? {
            login: {
              ...property?.login,
              credentials: {
                ...property?.login?.credentials,
                password: NOT_NULL_DEFAULT_PASSWORD,
              },
            },
          }
        : { login: values.login }),
    };
  }, [hasComplianceReportPremiumFeature, isLoading, property, params.id]);

  useEffect(() => {
    if (!isLoadingProperty && !property) {
      navigateTo('agnostik-compliance-report/add-domain');
    }
  }, [property, navigateTo, isLoadingProperty]);

  const handleSubmit = useCallback(
    async (property: Property) => {
      const hasPasswordChanged = property.login?.enabled && property.login?.credentials?.password !== NOT_NULL_DEFAULT_PASSWORD;

      if (!hasPasswordChanged) {
        delete property?.login?.credentials?.password;
      }

      try {
        await editDomain([property, params.id]);
        await displaySnackbar('Domain have been updated!', { icon: 'check' });
        navigateTo('agnostik-compliance-report/');
      } catch (error) {
        await displaySnackbar('There was an error updating the domain!', { variant: 'error' });
      }
    },
    [editDomain, displaySnackbar, navigateTo, params.id],
  );

  return (
    <PropertyForm
      initialValues={initialValues}
      isSaving={isUpdatingDomain}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      maxPages={quotas?.acm_scan_max_pages_per_property || 20}
    />
  );
};
