import React, { useCallback, useMemo, useState } from 'react';
import { filterSortPaginate } from '@didomi/helpers';
import { DidomiFiltersBarCustomEvent, DidomiFilterSelectOption } from '@didomi/ui-atoms';
import { DidomiFiltersBar, DidomiPaginator, DidomiSkeleton, DidomiTable, DidomiTableBody, DidomiTableHeaderRow, DidomiTableHeading, DidomiTableTh } from '@didomi/ui-atoms-react';
import { ComplianceReportDetailTableTrackerRowOld } from '@components';
import { ExemptionStatusFilterKey, TrackerLifetimeFilterKey, TrackerPartyFilterKey, TrackerType } from '@enums';
import { useTableState } from '@hooks';
import { InitiatorDetailModalOld } from '@modals';
import { AggregatedTracker, PropertyTrackerItem, Purpose, Report, ScenarioFilterKeyOld, SortConfig } from '@types';
import {
  defineTrackersTableDataOld,
  filterByFunctionalScenarioOld,
  filterTrackersByLifetime,
  filterTrackersByParty,
  filterTrackersByType,
  filterByExemptionStatus,
  assignCookieIds,
  addExemptionStatusAndPurposesToCookies,
} from '@utils';
import { ComplianceReportDetailTableEmptyRow } from './ComplianceReportDetailTableEmptyRow';
import { trackerFiltersOld } from './filters';

interface ComplianceReportCoreTrackerTableOldProps {
  report?: Report;
  forceTrackers?: any[];
  hideTableLabel?: boolean;
  isLoadingNodes?: boolean;
  nodes?: any;
  purposes?: Purpose[];
  propertyTrackerSettings?: PropertyTrackerItem[];
}

type SortableFields = 'name' | 'domain' | 'first_party' | 'initiator.label' | 'vendor.label' | 'type' | 'max_lifetime_seconds' | 'functional_scenario' | 'is_exempt';

type Filter = {
  type?: TrackerType[];
  lifetime?: TrackerLifetimeFilterKey[];
  party?: TrackerPartyFilterKey[];
  functionalScenario?: ScenarioFilterKeyOld[];
  isExempt?: ExemptionStatusFilterKey[];
};

type FilterChangeType = { key: string; newValue: string | DidomiFilterSelectOption | DidomiFilterSelectOption[] };

type FilterMatch = (tracker: AggregatedTracker, search?: Filter[keyof Filter]) => boolean;

const SEARCH_FIELDS = [{ field: 'name' }, { field: 'domain' }];

const FILTER_MAP: Record<keyof Filter, FilterMatch> = {
  type: filterTrackersByType,
  lifetime: filterTrackersByLifetime,
  party: filterTrackersByParty,
  functionalScenario: filterByFunctionalScenarioOld,
  isExempt: filterByExemptionStatus,
};

const INITIAL_SORT: SortConfig<SortableFields> = { field: 'domain', dir: 'asc' };

const ComplianceReportCoreTrackerTableOld = ({
  report,
  forceTrackers,
  hideTableLabel = false,
  isLoadingNodes = false,
  nodes = {},
  purposes = [],
  propertyTrackerSettings = [],
}: ComplianceReportCoreTrackerTableOldProps) => {
  const [sortConfig, setSortConfig] = useState<SortConfig<SortableFields>>(INITIAL_SORT);
  const [filter, setFilter] = useState<Filter>({});
  const [vendorPreviewId, setVendorPreviewId] = useState<string | null>(null);

  const updatedCookies = useMemo(() => {
    if (report?.details_json) {
      const reportCookies = assignCookieIds(report?.details_json.property.id, report?.details_json.property.cookies);
      return addExemptionStatusAndPurposesToCookies(reportCookies, purposes, propertyTrackerSettings, [], true);
    }

    return [];
  }, [report, propertyTrackerSettings, purposes]);
  const trackers = useMemo(() => forceTrackers || defineTrackersTableDataOld(updatedCookies), [updatedCookies, forceTrackers]);
  const filterConfig = useMemo(() => {
    return trackerFiltersOld(trackers);
  }, [trackers]);

  const {
    limit,
    page: currPage,
    search,
    changeLimit: setLimit,
    changeSearch: setSearch,
    changePage: setCurrPage,
  } = useTableState({
    defaultLimit: 10,
    defaultFilters: {},
  });

  const executeFilter = useCallback(
    (tracker: AggregatedTracker): boolean => {
      return Object.entries(filter).every(([filterKey, filterValue]) => FILTER_MAP[filterKey](tracker, filterValue));
    },
    [filter],
  );

  const { data: displayTrackers, total: paginationTotalTrackers } = useMemo(() => {
    return filterSortPaginate(trackers, {
      search,
      searchFields: SEARCH_FIELDS,
      limit,
      page: currPage,
      filters: [executeFilter],
      sortConfig,
    });
  }, [trackers, search, limit, currPage, executeFilter, sortConfig]);

  const handleSearchChange = (e: CustomEvent<string>) => setSearch(e.detail);

  const setFirstPage = () => {
    setCurrPage(
      new CustomEvent<{ page: number }>('pageChange', {
        detail: {
          page: 1,
        },
      }),
    );
  };

  const handleFilterChange = (newFilterChange: DidomiFiltersBarCustomEvent<FilterChangeType>) => {
    const key = newFilterChange.detail.key;
    const value = newFilterChange.detail.newValue;
    setFilter(currentFilter => ({ ...currentFilter, [key]: value }));
    setFirstPage();
  };

  const handleFiltersReset = () => {
    setSearch('');
    setFilter({});
    setSortConfig(INITIAL_SORT);
    setFirstPage();
  };

  const handleSortChange = (e: CustomEvent) => {
    const newSortBy = e.detail.sortId;
    const newSortDir = newSortBy !== sortConfig.field ? 'desc' : e.detail.direction;
    setFirstPage();
    setSortConfig({ dir: newSortDir, field: newSortBy ?? sortConfig.field });
  };

  const handleClose = () => {
    setVendorPreviewId(null);
  };

  const handleVendorChange = (id: string) => {
    setVendorPreviewId(id);
  };

  const displayPaginator = trackers.length > 0;

  return (
    <div>
      <DidomiSkeleton variant="layout">
        <div style={{ width: 'calc(100% - 4px)', margin: 'auto' }}>
          <DidomiFiltersBar
            data-testid="filter-bar-tracker"
            data-skeleton
            filters={filterConfig}
            id="didomi-filters-without-default-value-tracker"
            defaultValue=""
            leftText={hideTableLabel ? '' : `${paginationTotalTrackers} trackers`}
            onSearchTextChange={handleSearchChange}
            showSearch
            onFilterValueChange={handleFilterChange}
            placeholderTextFilter={'Search by name'}
            onClearAllFilters={handleFiltersReset}
            numberOfVisibleFilters={5}
          ></DidomiFiltersBar>
        </div>
        <div>
          <DidomiTable
            sortable
            sortBy={sortConfig.field}
            sortDirection={sortConfig.dir}
            onSortChange={handleSortChange}
            style={{ width: 'calc(100% - 2px)', margin: '16px auto' }}
            data-skeleton
          >
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                <DidomiTableTh sortId="name" data-testid="sortByName">
                  Tracker name
                </DidomiTableTh>
                <DidomiTableTh sortId="domain" data-testid="sortByDomain">
                  Domain
                </DidomiTableTh>
                <DidomiTableTh sortId="first_party" data-testid="sortByFirstParty">
                  1ST/3RD party
                </DidomiTableTh>
                <DidomiTableTh sortId="initiator.label" data-testid="sortByInitiator">
                  Initiator
                </DidomiTableTh>
                <DidomiTableTh sortId="vendor.label" data-testid="sortByVendor">
                  Vendor
                </DidomiTableTh>
                <DidomiTableTh sortId="type" data-testid="sortByType">
                  Type
                </DidomiTableTh>
                <DidomiTableTh sortId="max_lifetime_seconds" data-testid="sortByLifetime">
                  Lifetime
                </DidomiTableTh>
                <DidomiTableTh sortId="functional_scenario" data-testid="sortByFunctionalScenario">
                  User behaviour
                </DidomiTableTh>
                <DidomiTableTh>Ran scenarios</DidomiTableTh>
                <DidomiTableTh data-testid="purposes">Purposes</DidomiTableTh>
                <DidomiTableTh sortId="is_exempt" data-testid="is_exempt">
                  Exemption
                </DidomiTableTh>
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody data-skeleton>
              {displayTrackers.length > 0 && (
                <ComplianceReportDetailTableTrackerRowOld trackers={displayTrackers} onVendorClick={setVendorPreviewId} isLoadingNodes={isLoadingNodes} nodes={nodes} />
              )}
              {displayTrackers.length === 0 && <ComplianceReportDetailTableEmptyRow />}
            </DidomiTableBody>
          </DidomiTable>

          {displayPaginator && (
            <DidomiPaginator
              data-skeleton
              className="self-end mt-xs w-full justify-end flex"
              variant="normal"
              page={currPage}
              itemCount={paginationTotalTrackers}
              size={limit}
              onPageSizeChange={setLimit}
              onPageChange={setCurrPage}
            />
          )}
        </div>
      </DidomiSkeleton>
      <InitiatorDetailModalOld
        isOpen={vendorPreviewId !== null}
        vendorId={vendorPreviewId}
        onClose={handleClose}
        report={report}
        onVendorChange={handleVendorChange}
        nodes={nodes}
      />
    </div>
  );
};

export { ComplianceReportCoreTrackerTableOld };
