import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { NoticeTemplateVendor } from '../../../interfaces/notice-template-vendor.interface';
import { DeleteNoticeTemplateVendorPayload } from '../../../types/payloads/deleteNoticeTemplateVendorPayload';

export const useDeleteNoticeTemplateVendors = (
  noticeTemplateId: string,
  options?: UseMutationOptions<AxiosResponse<NoticeTemplateVendor[]>, AxiosError, DeleteNoticeTemplateVendorPayload[]>,
) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const remove = async (data: DeleteNoticeTemplateVendorPayload[]) => {
    return await axiosWithInterceptors.delete<NoticeTemplateVendor[]>(`${apiRoutes.noticeTemplates}/${noticeTemplateId}/vendors`, {
      params: {
        organization_id: organizationId,
        metadata_partner_id: data.map(entity => entity.metadata_partner_id),
      },
    });
  };

  return useMutation<AxiosResponse<NoticeTemplateVendor[]>, AxiosError, DeleteNoticeTemplateVendorPayload[]>(remove, {
    ...options,
    onSuccess: (deletedEntities: AxiosResponse<NoticeTemplateVendor[]>, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['notice-template-vendors', { organization_id: organizationId }] });

      if (options?.onSuccess) {
        options.onSuccess(deletedEntities, variables, context);
      }
    },
  });
};
