import { TRANSLATED_SCENARIOS_MAP } from '@constants';
import { ScenarioType } from '@enums';

/**
 * Filters and concatenates succeeded scenario keys from a scenarioTypes into a string.
 * @returns {string} A comma-separated string of translated scenario keys.
 * @param scenarioTypes
 */
export const getRanScenarios = (scenarioTypes: ScenarioType[]): string => {
  const succeededScenarios = scenarioTypes || [];

  if (!succeededScenarios.length) {
    return '-';
  }

  const scenarios = succeededScenarios.map(scenario => TRANSLATED_SCENARIOS_MAP[scenario] || scenario).sort((a, b) => a.localeCompare(b));

  return scenarios.join(', ');
};
