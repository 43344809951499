export const apiRoutes = {
  members: `${CONFIG.environmentConfig.apiBaseUrl}members`,
  memberPropertyNotifications: `${CONFIG.environmentConfig.apiBaseUrl}reports/compliances/member-property-notifications`,
  properties: `${CONFIG.environmentConfig.apiBaseUrl}properties`,
  // TODO: remove v2 after migration
  propertiesV2: `${CONFIG.environmentConfig.apiBaseUrl}reports/compliances/properties`,
  exemptionCategories: `${CONFIG.environmentConfig.apiBaseUrl}reports/compliances/exemption-categories`,
  crProperties: `${CONFIG.environmentConfig.apiBaseUrl}reports/compliances/properties`,
  reports: `${CONFIG.environmentConfig.apiBaseUrl}agnostik/reports/compliances`,
  crVendors: `${CONFIG.environmentConfig.apiBaseUrl}reports/compliances/vendors`,
  reportV2: (id: string) => `${CONFIG.environmentConfig.apiBaseUrl}reports/compliances/reports/${id}`,
  reportsV2: `${CONFIG.environmentConfig.apiBaseUrl}reports/compliances/reports`,
  noticeTemplates: `${CONFIG.environmentConfig.apiBaseUrl}reports/compliances/notice-templates`,
};
