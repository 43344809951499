import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut, useAllPurposes } from '@hooks';
import { PaginatedResponse, Partner, PatnerRequestBody } from '@types';
import { mapVendorResult } from '@utils';

/**
 * Creates a vendor, makes sure to update the list of vendors with the new added vendor
 * */
export const useCreateVendor = (options?: UseMutationOptions<AxiosResponse<Partner>, AxiosError, PatnerRequestBody>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const { data: purposes } = useAllPurposes();

  return useMutation(
    async (vendor: PatnerRequestBody) => {
      return axiosWithInterceptors.post(`${CONFIG.environmentConfig.apiBaseUrl}metadata/partners?$translations=true`, vendor);
    },
    {
      ...options,
      onSuccess: (newVendor: AxiosResponse<Partner>, variables, context) => {
        const newVendorMapped = mapVendorResult(newVendor.data, purposes?.data);

        const vendorsGet: PaginatedResponse<Partner> = queryClient.getQueryData(['vendors', organizationId]);
        /* istanbul ignore if */
        if (vendorsGet) {
          queryClient.setQueryData(['vendors', organizationId], {
            ...vendorsGet,
            data: vendorsGet.data.concat(newVendorMapped),
            total: vendorsGet.total + 1,
          });
        }

        if (options?.onSuccess) {
          options.onSuccess(newVendor, variables, context);
        }
        // Remove `type` property which has been added by the Admin API but doesn't exist in the GCP CR API
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { type, ...vendorToSave } = newVendor.data;
        return axiosWithInterceptors.post(`${CONFIG.environmentConfig.apiBaseUrl}agnostik/metadata-partners?organization_id=${organizationId}`, vendorToSave);
      },
    },
  );
};
