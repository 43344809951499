/* istanbul ignore file */
import { useParams } from 'react-router-dom';
import React, { useState, useMemo } from 'react';
import { getCounterText } from '@didomi/helpers';
import { DidomiTooltip, DidomiSkeleton, DidomiBottomBar, DidomiButton, DidomiModal, DidomiModalContent, DidomiHintbox, DidomiLoader } from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';
import { OldManageTrackerPolicyTable } from '@components';
import { PropertyWithOptions } from '@enums';
import {
  usePropertyReport,
  usePropertyTrackerSettings,
  useTrackerExemptionCategories,
  usePatchPropertyTrackersOld,
  useClonePropertyTrackerSettings,
  useCustomAndIABPurposes,
} from '@hooks';
import { AssignTrackerPurposesModal, CloneTrackerSettingsModal, EditTrackerModal, ExemptTrackerModal } from '@modals';
import { AggregatedTracker, PropertyTrackerItem } from '@types';
import { defineTrackersTableDataOld, applyPropertyTrackerSettingsToCookies, getLastAnalyzedReport } from '@utils';
import { useProperties } from '../../hooks/data/properties/useProperties.hook';
import { useProperty } from '../../hooks/data/properties/useProperty.hook';

export const OldManageTrackerPolicy = () => {
  const params = useParams();
  const { displaySnackbar } = useSnackbar();

  const propertyIdParam = params?.propertyId;

  const [selectedTrackerIds, setSelectedTrackerIds] = useState<string[]>([]);
  const [selectedPurposeIds, setSelectedPurposeIds] = useState<string[]>([]);
  const [selectedPropertyIds, setSelectedPropertyIds] = useState<string[]>([]);
  const [selectedTrackers, setSelectedTrackers] = useState<AggregatedTracker[]>([]);
  const [isSelectExemptedTracker, setIsSelectExemptedTracker] = useState(false);
  const [isSelectNonExemptedTracker, setIsSelectNonExemptedTracker] = useState(false);
  const [isSelectPurposeAssignedTrackers, setisSelectPurposeAssignedTrackers] = useState(false);
  const [isOpenExemptTrackerDialog, setIsOpenExemptTrackerDialog] = useState(false);
  const [isOpenPurposeAssignmentDialog, setIsOpenPurposeAssignmentDialog] = useState(false);
  const [isOpenCloneTrackerSettingsDialog, setIsOpenCloneTrackerSettingsDialog] = useState(false);
  const [isOpenEditPropertyTrackerDialog, setIsOpenEditPropertyTrackerDialog] = useState(false);

  const { isLoading, data: property } = useProperty({ id: propertyIdParam, with: [PropertyWithOptions.LATEST_REPORTS] });
  const { isLoading: isLoadingPurposes, data: purposesToSelect } = useCustomAndIABPurposes();
  // TODO: Improve UI/UX to make this feature works for organizations with more than 1000 properties
  const { isLoading: isLoadingProperties, data: properties } = useProperties({
    sort: { field: 'name', dir: 'asc' },
    limit: 1000,
  });
  const { isLoading: isLoadingExemptionCategories, data: exemptionCategories } = useTrackerExemptionCategories();
  const { isLoading: isSavingTrackerSettings, mutateAsync: patchPropertyTrackers } = usePatchPropertyTrackersOld();
  const { isLoading: isCloningTrackerSettings, mutateAsync: clonePropertyTrackersSetting } = useClonePropertyTrackerSettings();

  const lastReport = property?.latest_reports ? getLastAnalyzedReport(property) : undefined;
  const { isLoading: reportLoading, data: report } = usePropertyReport({ reportId: lastReport?.id, enabled: !!lastReport?.id });
  const { isLoading: isLoadingPropertyTrackerSettings, data: propertyTrackerSettings } = usePropertyTrackerSettings({
    propertyId: propertyIdParam,
  });

  const trackers = useMemo(() => {
    const updatedCookies = applyPropertyTrackerSettingsToCookies(
      report?.details_json?.property?.id || propertyIdParam,
      report?.details_json?.property?.cookies || [],
      purposesToSelect || [],
      propertyTrackerSettings?.data || [],
    );
    return defineTrackersTableDataOld(updatedCookies);
  }, [report, propertyIdParam, propertyTrackerSettings, purposesToSelect]);

  const handleSubmitAssignPurposesAndExemption = async ({
    selectedPurposeIds,
    selectedExemptionCategoryId,
  }: {
    selectedPurposeIds?: string[];
    selectedExemptionCategoryId?: string;
  }) => {
    try {
      const selectedTrackers: PropertyTrackerItem[] = trackers
        .filter(tracker => selectedTrackerIds.includes(tracker.id))
        .map((tracker: any) => ({
          name: tracker.name,
          is_regex: tracker?.is_regex || false,
          is_third_party: !tracker.first_party,
          initiator_vendor_id: tracker.initiator?.identifier,
          initiator_vendor_name: tracker.initiator?.label,
          vendor_id: tracker.vendor?.identifier,
          vendor_name: tracker.vendor?.label,
          host: tracker?.host,
          type: tracker.type,
          functional_scenario: tracker?.functional_scenario,
          exemption_category_id: selectedExemptionCategoryId,
          purpose_ids: selectedPurposeIds,
        }));

      await patchPropertyTrackers({
        propertyId: property.id,
        trackers: selectedTrackers,
      });

      setSelectedTrackerIds([]);
      setIsOpenExemptTrackerDialog(false);
      setIsOpenPurposeAssignmentDialog(false);

      await displaySnackbar('Tracker settings has been saved successfully', { icon: 'success-small' });
    } catch (error) {
      await displaySnackbar('There was an error on saving tracker settings: ' + error.message, { variant: 'error' });
    }
  };

  const handleSubmitClonePropertyTrackerSettings = async (selectedPropertyIds: string[]) => {
    try {
      await clonePropertyTrackersSetting({
        sourcePropertyId: property.id,
        targetPropertyIds: selectedPropertyIds,
      });

      setSelectedPropertyIds([]);
      setIsOpenCloneTrackerSettingsDialog(false);

      await displaySnackbar('Property tracker settings have been copied successfully!', { icon: 'success-small' });
    } catch (error) {
      await displaySnackbar('There was an error on  the property trackers: ' + error.message, { variant: 'error' });
    }
  };

  const handlerSubmitEditTracker = async (data: AggregatedTracker) => {
    try {
      const tracker = selectedTrackers[0] as any;
      await patchPropertyTrackers({
        propertyId: property.id,
        trackers: [
          {
            name: data.name,
            is_regex: data.is_regex,
            is_third_party: !tracker.first_party,
            initiator_vendor_id: tracker?.initiator?.identifier,
            initiator_vendor_name: tracker?.initiator?.label,
            vendor_id: tracker?.vendor?.identifier,
            vendor_name: tracker?.vendor?.label,
            host: tracker?.host,
            type: tracker?.type,
            exemption_category_id: tracker?.exemption_category_id,
            purpose_ids: tracker?.purpose_ids,
            functional_scenario: tracker?.functional_scenario,
          },
        ],
      });

      setSelectedTrackerIds([]);
      setIsOpenEditPropertyTrackerDialog(false);

      await displaySnackbar('Tracker settings has been saved successfully', { icon: 'success-small' });
    } catch (error) {
      await displaySnackbar('There was an error on saving tracker settings: ' + error.message, { variant: 'error' });
    }
  };

  const handlerTrackerSelection = (selectedTrackerIds: string[]) => {
    const selectedTrackers = trackers.filter(tracker => selectedTrackerIds.includes(tracker.id));
    const hasSelectExemptedTracker = selectedTrackers.some(tracker => tracker.exemption_category_id);
    const hasSelectNonExemptedTracker = selectedTrackers.some(tracker => !tracker.exemption_category_id);
    const hasSelectPurposeAssignedTrackers = selectedTrackers.some(tracker => tracker?.purpose_ids?.length > 0);

    const selectedPurposeIds =
      selectedTrackers.length === 1
        ? selectedTrackers
            .map(tracker => tracker.purpose_ids)
            .flat()
            .filter(Boolean)
        : [];

    setSelectedTrackers(selectedTrackers);
    setSelectedTrackerIds(selectedTrackerIds);
    setSelectedPurposeIds(selectedPurposeIds);
    setIsSelectExemptedTracker(hasSelectExemptedTracker);
    setIsSelectNonExemptedTracker(hasSelectNonExemptedTracker);
    setisSelectPurposeAssignedTrackers(hasSelectPurposeAssignedTrackers);
  };

  const isBottomBarOpened = selectedTrackerIds.length > 0;
  const isDisableModifyExemption = isSelectExemptedTracker && isSelectNonExemptedTracker;
  const propertiesToSelect = properties?.data.filter(property => property.id !== propertyIdParam);
  const isLoadingReady = isLoading || reportLoading || isLoadingProperties || isLoadingPurposes || isLoadingExemptionCategories || isLoadingPropertyTrackerSettings;

  return (
    <div className="pb-unsafe-bottom-space">
      <DidomiHintbox title-text="Choose one or more trackers that you want to configure.">
        <div>The compliance monitoring checks for all active trackers. On this page you can configure exemptions and purposes for each of them.</div>
      </DidomiHintbox>
      <div className="mt-[15px]">
        <DidomiSkeleton isLoading={isLoadingReady} variant="unit" className="h-40 w-full">
          {!isLoadingReady && (
            <div>
              <OldManageTrackerPolicyTable
                trackers={trackers}
                selectedIds={selectedTrackerIds}
                onSelectionChange={handlerTrackerSelection}
                setOpenCloneTrackerSettingsDialog={setIsOpenCloneTrackerSettingsDialog}
              />
            </div>
          )}
        </DidomiSkeleton>
      </div>

      <DidomiBottomBar
        class="-mx-l !fixed w-full"
        icon="check"
        isOpen={isBottomBarOpened}
        onClose={() => setSelectedTrackerIds([])}
        text={isBottomBarOpened ? `${getCounterText(selectedTrackerIds.length, 'tracker')} selected` : null}
      >
        <div slot="actions" style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <DidomiButton size="medium" onClick={() => setIsOpenPurposeAssignmentDialog(true)}>
            {(isSelectPurposeAssignedTrackers && 'Modify purpose') || 'Assign purpose'}&nbsp;
          </DidomiButton>
          {isDisableModifyExemption ? (
            <>
              <DidomiTooltip contentElementId={`disable_exemption_button_help`} variant="helper" distance="xxxs">
                <span>
                  <DidomiButton size="medium" onClick={() => setIsOpenExemptTrackerDialog(true)} disabled={true}>
                    {(!isSelectExemptedTracker && 'Exempt tracker') || (!isSelectNonExemptedTracker && 'Modify exemption') || 'Modify exemption'}&nbsp;
                  </DidomiButton>
                </span>
              </DidomiTooltip>
              <div style={{ display: 'none' }} id={`disable_exemption_button_help`}>
                <p>You have selected multiple trackers that have different exemption status.</p>
                <p>&nbsp;</p>
                <p>In order to change exemption status, please select trackers that have the same settings.</p>
              </div>
            </>
          ) : (
            <>
              <DidomiButton size="medium" onClick={() => setIsOpenExemptTrackerDialog(true)}>
                {(!isSelectExemptedTracker && 'Exempt tracker') || (!isSelectNonExemptedTracker && 'Modify exemption') || 'Modify exemption'}&nbsp;
              </DidomiButton>
            </>
          )}
          {selectedTrackers.length === 1 && !selectedTrackers[0].is_regex && (
            <DidomiButton data-testid="edit-tracker-button" size="medium" onClick={() => setIsOpenEditPropertyTrackerDialog(true)}>
              Edit
            </DidomiButton>
          )}
        </div>
      </DidomiBottomBar>

      {/** Dialog to display busy state on data processing */}
      <DidomiModal isOpen={isSavingTrackerSettings || isCloningTrackerSettings}>
        <DidomiLoader></DidomiLoader>
        <DidomiModalContent className="mt-m">{'We are saving your tracker settings ...'}</DidomiModalContent>
      </DidomiModal>

      {/** Dialog to display modify purposes form */}
      {purposesToSelect && (
        <AssignTrackerPurposesModal
          purposes={purposesToSelect}
          selectedPurposeIds={selectedPurposeIds}
          isOpen={isOpenPurposeAssignmentDialog}
          onClose={setIsOpenPurposeAssignmentDialog}
          setSelectedPurposeIds={setSelectedPurposeIds}
          onSubmit={handleSubmitAssignPurposesAndExemption}
        />
      )}

      {/** Dialog to display modify exemption form */}
      <ExemptTrackerModal
        tracker={selectedTrackers[0] || null}
        isOpen={isOpenExemptTrackerDialog}
        onClose={setIsOpenExemptTrackerDialog}
        hideRemoveExemption={isSelectNonExemptedTracker}
        exemptionCategories={exemptionCategories?.data}
        onSubmit={handleSubmitAssignPurposesAndExemption}
      />

      {/** Dialog to display clone property tracker settings form */}
      {propertiesToSelect && (
        <CloneTrackerSettingsModal
          properties={propertiesToSelect}
          selectedPropertyIds={selectedPropertyIds}
          isOpen={isOpenCloneTrackerSettingsDialog}
          onOptionSelect={setSelectedPropertyIds}
          onClose={setIsOpenCloneTrackerSettingsDialog}
          onSubmit={handleSubmitClonePropertyTrackerSettings}
        />
      )}

      {/** Dialog to create new tracker by modify an existing tracker information by place regex as name of the tracker */}
      <EditTrackerModal tracker={selectedTrackers[0]} isOpen={isOpenEditPropertyTrackerDialog} onClose={setIsOpenEditPropertyTrackerDialog} onSubmit={handlerSubmitEditTracker} />
    </div>
  );
};
