import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { NoticeTemplate } from '../../../interfaces/notice-template.interface';
import { CreateNoticeTemplatePayload } from '../../../types/payloads/createNoticeTemplatePayload';

export const useCreateNoticeTemplate = (options?: UseMutationOptions<AxiosResponse<NoticeTemplate>, AxiosError, CreateNoticeTemplatePayload>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const create = async (payload: CreateNoticeTemplatePayload) => {
    return await axiosWithInterceptors.post<NoticeTemplate>(`${apiRoutes.noticeTemplates}`, payload, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation(create, {
    ...options,
    onSuccess: (createdEntity: AxiosResponse<NoticeTemplate>, variables, context) => {
      const createdNoticeTemplate = createdEntity.data;

      if (createdNoticeTemplate?.id) {
        queryClient.setQueryData<NoticeTemplate[]>(['notice-templates', { organization_id: organizationId }], oldData => {
          return Array.isArray(oldData) ? [...oldData, createdNoticeTemplate] : [createdNoticeTemplate];
        });

        queryClient.invalidateQueries({ queryKey: ['notice-templates', { organization_id: organizationId, id: createdNoticeTemplate.id }] });
      }

      if (options?.onSuccess) {
        options.onSuccess(createdEntity, variables, context);
      }
    },
  });
};
