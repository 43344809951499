import { SCENARIO_KEYS, TRANSLATED_SCENARIOS_MAP } from '@constants';
import { TCFStatusType, TrackerLifetimeFilterKey, TrackerPartyFilterKey, TrackerType, VendorLegalBasisFilterKey, ExemptionStatusFilterKey, ScenarioType } from '@enums';
import { AggregatedTracker, AggregatedVendor, LegalBasis, ScenarioFilterKeyOld } from '@types';
import { computeFunctionalScenario } from '@utils';
import { lifetimeFiltersLookup } from '../components/ComplianceReportDetail/filters';

/**
 * Filter the vendor list based on their legal basis.
 * Behavior:
 * - Select "Consent only": Discard vendors that don't belong to "Consent only" legal basis.
 * - Select "Legitimate interest": Discard vendors that don't belong to "Legitimate interest" legal basis.
 * - Select "Both": Discard vendors that don't belong to "Legitimate interest AND Consent only" legal basis.
 * Note: it expects one or multiple filter keys.
 * @param vendor
 * @param selectedLegalBasis
 */
export const filterByLegalBasisOld = (vendor: Partial<AggregatedVendor>, selectedLegalBasis: VendorLegalBasisFilterKey[] = []) => {
  if (!selectedLegalBasis.length) return true;

  const vendorLegalBasisConditions: Record<VendorLegalBasisFilterKey, boolean> = {
    [VendorLegalBasisFilterKey.CONSENT_ONLY]: vendor.legalBasis === LegalBasis.CONSENT_ONLY,
    [VendorLegalBasisFilterKey.LEGITIMATE_INTEREST]: vendor.legalBasis === LegalBasis.LEGITIMATE_INTEREST,
    [VendorLegalBasisFilterKey.BOTH]: vendor.legalBasis === LegalBasis.LEGITIMATE_INTEREST_CONSENT_ONLY,
  };

  return selectedLegalBasis.some((legalBasis: VendorLegalBasisFilterKey) => vendorLegalBasisConditions[legalBasis]);
};

/**
 * Filter the vendor list based on their legal basis.
 * @param vendor
 * @param selectedLegalBasis
 */
export const filterByLegalBasis = (vendor: Partial<AggregatedVendor>, selectedLegalBasis: VendorLegalBasisFilterKey[] = []) => {
  if (!selectedLegalBasis.length) return true;

  const vendorLegalBasisConditions: Record<VendorLegalBasisFilterKey, boolean> = {
    [VendorLegalBasisFilterKey.CONSENT_ONLY]: vendor?.legal_basis === LegalBasis.CONSENT_ONLY,
    [VendorLegalBasisFilterKey.LEGITIMATE_INTEREST]: vendor?.legal_basis === LegalBasis.LEGITIMATE_INTEREST,
    [VendorLegalBasisFilterKey.BOTH]: vendor?.legal_basis === LegalBasis.LEGITIMATE_INTEREST_CONSENT_ONLY,
  };

  return selectedLegalBasis.some((legalBasis: VendorLegalBasisFilterKey) => vendorLegalBasisConditions[legalBasis]);
};

/**
 * Filter the vendor list based on if they are disclosed in the TCF or not.
 * @param vendor
 * @param selectedStatuses
 */
export const filterVendorByTCFStatusOld = (vendor: Partial<AggregatedVendor>, selectedStatuses: TCFStatusType[] = []): boolean =>
  !selectedStatuses.length || selectedStatuses.includes(vendor.tcfId !== '-' ? TCFStatusType.ACTIVE : TCFStatusType.INACTIVE);

/**
 * Filter the vendor list based on if they are disclosed in the TCF or not.
 * @param vendor
 * @param selectedStatuses
 */
export const filterVendorByTCFStatus = (vendor: Partial<AggregatedVendor>, selectedStatuses: TCFStatusType[] = []): boolean => {
  if (!selectedStatuses.length) return true;

  return selectedStatuses.includes(vendor.partner?.namespaces?.iab2 ? TCFStatusType.ACTIVE : TCFStatusType.INACTIVE);
};

/**
 * Filter the tracker list based on their lifetime.
 * @param tracker
 * @param selectedLifetimes
 */
export const filterTrackersByLifetime = (tracker: Partial<AggregatedTracker>, selectedLifetimes: TrackerLifetimeFilterKey[] = []) => {
  if (!selectedLifetimes.length) return true;

  return selectedLifetimes.some((lifetimeKey: TrackerLifetimeFilterKey) => lifetimeFiltersLookup[lifetimeKey].isValid(tracker));
};

/**
 * Filter the tracker list based on their functional scenario.
 * @param entity
 * @param selectedFunctionalScenarios
 */
export const filterByFunctionalScenarioOld = (entity: Partial<AggregatedTracker | AggregatedVendor>, selectedFunctionalScenarios: ScenarioFilterKeyOld[] = []) => {
  if (!selectedFunctionalScenarios.length) return true;

  const functionalScenarioConditions: Record<ScenarioFilterKeyOld, boolean> = {
    NO_USER_CHOICE: entity.functional_scenario === SCENARIO_KEYS.NO_USER_CHOICE,
    CONSENT_TO_ALL: entity.functional_scenario === SCENARIO_KEYS.CONSENT_TO_ALL,
    REFUSE_TO_ALL: entity.functional_scenario === SCENARIO_KEYS.REFUSE_TO_ALL,
  };

  return selectedFunctionalScenarios.some((functionalScenario: ScenarioFilterKeyOld) => functionalScenarioConditions[functionalScenario]);
};

/**
 * Filter the tracker list based on their functional scenario.
 * @param entity
 * @param selectedFunctionalScenarios
 */
export const filterTrackersByFunctionalScenario = (tracker: Partial<AggregatedTracker>, selectedFunctionalScenarios: ScenarioType[] = []) => {
  if (!selectedFunctionalScenarios.length) return true;

  // @ts-ignore
  return selectedFunctionalScenarios.some((functionalScenario: ScenarioType) => tracker.functional_scenario === functionalScenario);
};

/**
 * Filter the vendor list based on their functional scenario.
 * @param vendor
 * @param selectedFunctionalScenarios
 */
export const filterVendorsByFunctionalScenario = (vendor: Partial<AggregatedVendor>, selectedFunctionalScenarios: ScenarioType[] = []) => {
  const functionalScenario = TRANSLATED_SCENARIOS_MAP[computeFunctionalScenario(vendor.cmp.scenario.id as ScenarioType[])];
  if (!selectedFunctionalScenarios.length) return true;

  return selectedFunctionalScenarios.some((selectedFunctionalScenarios: ScenarioType) => functionalScenario === selectedFunctionalScenarios);
};

/**
 * Filter the tracker list based on their party (1st party / 3rd party).
 * @param tracker
 * @param selectedParties
 */
export const filterTrackersByParty = (tracker: Partial<AggregatedTracker>, selectedParties: TrackerPartyFilterKey[] = []) => {
  if (!selectedParties.length) return true;

  const trackerPartyConditions: Record<TrackerPartyFilterKey, boolean> = {
    [TrackerPartyFilterKey.FIRST_PARTY]: !!tracker.first_party,
    [TrackerPartyFilterKey.THIRD_PARTY]: !tracker.first_party,
  };

  return selectedParties.some((party: TrackerPartyFilterKey) => trackerPartyConditions[party]);
};

/**
 * Filter the tracker list based on their type.
 * @param tracker
 * @param selectedTypes
 */
export const filterTrackersByType = (tracker: Partial<AggregatedTracker>, selectedTypes: TrackerType[] = []) => {
  if (!selectedTypes.length) return true;

  const trackerTypeConditions: Record<TrackerType, boolean> = {
    [TrackerType.PIXEL]: tracker.type === TrackerType.PIXEL,
    [TrackerType.COOKIE]: tracker.type === TrackerType.COOKIE,
    [TrackerType.WEB_STORAGE]: tracker.type === TrackerType.WEB_STORAGE,
    [TrackerType.INDEXED_DB]: tracker.type === TrackerType.INDEXED_DB,
  };

  return selectedTypes.some((type: TrackerType) => trackerTypeConditions[type]);
};

/**
 * Filter the tracker list based on their exemption status.
 *
 * @param tracker
 * @param selectedParties
 */
export const filterByExemptionStatus = (tracker: Partial<AggregatedTracker>, selectedExemptionStatus: ExemptionStatusFilterKey[] = []) => {
  if (!selectedExemptionStatus.length) return true;

  return selectedExemptionStatus.some((exemptionStatus: ExemptionStatusFilterKey) =>
    Number(exemptionStatus) === ExemptionStatusFilterKey.YES ? tracker.is_exempt : !tracker.is_exempt,
  );
};
