import React from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiTableRow, DidomiTableTd } from '@didomi/ui-atoms-react';
import { TRANSLATED_SCENARIOS_MAP } from '@constants';
import { AggregatedTracker } from '@types';
import { getTrackerTypeValue, getRanScenarios } from '@utils';
import { TruncatedTextWithTooltip } from '../TruncatedTextWithTooltip/TruncatedTextWithTooltip';

interface Props {
  trackers: AggregatedTracker[];
  onVendorClick: (vendor: string) => void;
  isLoadingNodes: boolean;
  nodes: any;
}
export const ComplianceReportDetailTableTrackerRow = ({ trackers = [], onVendorClick, isLoadingNodes, nodes }: Props) => {
  return (
    <>
      {trackers.map((tracker, index) => (
        <DidomiTableRow data-skeleton key={`tracker-${tracker.label}-${index}`}>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={tracker.name} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={tracker.domain} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={tracker.party} />
          </DidomiTableTd>
          <DidomiTableTd>
            <button
              data-testid="initiator-button"
              className={`underline underline-offset-2 outline-none text-left contents ${isLoadingNodes && 'cursor-wait'}`}
              title={isLoadingNodes ? 'Processing associations...' : undefined}
              onClick={() => !isLoadingNodes && onVendorClick(tracker.initiator.identifier)}
            >
              <TruncatedTextWithTooltip value={tracker.initiator.label} className="underline underline-offset-2" />
            </button>
          </DidomiTableTd>
          <DidomiTableTd>
            <button
              data-testid="vendor-button"
              className={`outline-none text-left contents ${isLoadingNodes && 'cursor-wait'}`}
              title={isLoadingNodes ? 'Processing associations...' : undefined}
              onClick={() => !isLoadingNodes && nodes?.[tracker.vendor.identifier] && onVendorClick(tracker.vendor.identifier)}
            >
              <TruncatedTextWithTooltip value={tracker.vendor.label} className="underline underline-offset-2" />
            </button>
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={getTrackerTypeValue(tracker.type)} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={tracker.lifetime} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={TRANSLATED_SCENARIOS_MAP[tracker.functional_scenario]} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={getRanScenarios(tracker)} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={tracker?.purposes?.length > 0 ? tracker?.purposes.map(purpose => getTranslatedValue(purpose.description)).join(', ') : 'None'} />
          </DidomiTableTd>
          <DidomiTableTd>
            <span className={`inline-block truncate w-full py-1`}>{tracker.is_exempt ? 'Yes' : 'No'}</span>
          </DidomiTableTd>
        </DidomiTableRow>
      ))}
    </>
  );
};
