import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosResponse, AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { apiRoutes } from '@constants';
import { OrganizationProperty, Report } from '@types';
import { Property } from 'types/responses/property';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useGeneratePropertyReport = (options: UseMutationOptions<AxiosResponse<Report>, AxiosError, Pick<Property, 'id' | 'organization_id'>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  return useMutation(async (option: Partial<OrganizationProperty>) => {
    const requestBody = {
      property_id: option.id,
    };
    //@todo: update endpoint
    return axiosWithInterceptors.post<Report>(apiRoutes.reportsV2, requestBody, {
      params: {
        organization_id: organizationId,
      },
    });
  }, options);
};
