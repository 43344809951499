import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { apiRoutes } from '@constants';
import { PropertyWithOptions } from '@enums';
import { useAxiosWithLogOut } from '@hooks';
import { convertLegacyReportStatus } from '@utils';
import { Property } from 'types/responses/property';

type UsePropertyOptions = UseQueryOptions<Property, AxiosError> & {
  with?: PropertyWithOptions[];
  id: string;
};

export const useProperty = (options: UsePropertyOptions) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<Property>(`${apiRoutes.propertiesV2}/${options.id}`, {
      params: {
        organization_id: organizationId,
        $with: options.with || [],
      },
    });

    response.data.latest_reports?.forEach(report => {
      report.status = convertLegacyReportStatus(report.status);
    });

    return response.data;
  };

  return useQuery<Property, AxiosError>(['property', options.id, organizationId], fetch, options);
};
