export const VENDOR_GRAPH_NODE_COLORS = ['#D1EFFA', '#21c6b2', 'rgb(245, 192, 90)', 'rgb(238, 128, 91)'];

export const VENDOR_GRAPH_OPTIONS = {
  interaction: {
    selectConnectedEdges: false,
  },
  nodes: {
    shape: 'dot',
    size: 2,
    color: '#D1EFFA',
    font: {
      face: '"IBM Plex Sans", Helvetica, Arial',
      size: 24,
      color: '#666',
    },
    borderWidth: 0.5,
    borderWidthSelected: 4,
  },
  edges: {
    dashes: false,
    selectionWidth: 4,
  },
  physics: {
    forceAtlas2Based: {
      gravitationalConstant: -26,
      centralGravity: 0.005,
      springLength: 230,
      springConstant: 0.18,
    },
    maxVelocity: 146,
    solver: 'forceAtlas2Based',
    timestep: 0.35,
    stabilization: {
      enabled: true,
      fit: true,
    },
  },
  layout: {
    randomSeed: 34,
  },
};
