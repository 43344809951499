import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { DidomiBackButtonLink, DidomiDropdown, DidomiOption, DidomiSecondaryHeader, DidomiSkeleton } from '@didomi/ui-atoms-react';

import { useProperties } from '@hooks';
import { SortConfig } from '@types';
import { Property } from '../../types/responses/property';

interface IMainLayoutProps {
  children: React.ReactChild;
}

const SORT: SortConfig<keyof Property> = {
  field: 'name',
  dir: 'asc',
};

/**
 * Report detail Layout
 *
 * TODO: Currently this dropdown will only contain up to 1000 properties.
 * It does not make sense to use pagination here and/or display more than 1000 properties in a dropdown.
 * Think about how useful is this component and how to improve it or remove it.
 */
export const ReportDetailLayout = ({ children }: IMainLayoutProps): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const { data: { data: listProperties } = {}, isLoading, error } = useProperties({ sort: SORT, limit: 1000 });

  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);

  useEffect(() => {
    if (!isLoading && (error || listProperties.length === 0)) {
      return navigate('/');
    }
  }, [isLoading, listProperties, navigate, error]);

  useEffect(() => {
    if (params.propertyId && listProperties?.length) {
      setSelectedProperty(listProperties.find(property => property.id === params.propertyId));
    }
  }, [params.propertyId, listProperties]);

  const handleSelectChange = (property: Property) => {
    setSelectedProperty(property);
    navigate(`/domain/${property.id}`);
  };

  const getPropertyNameAndWebsite = (property: Property) => {
    if (!property) return '';

    const website = property.website ? property.website.replace(/^https?:\/\//, '') : 'no website';

    return `${property.name} (${website})`;
  };

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoading}>
      <section className="p-l pb-20 h-full box-border !flex flex-col">
        <div>
          <DidomiSecondaryHeader title-text="History & Proofs" className="flex flex-row">
            <DidomiBackButtonLink
              className="cursor-pointer	"
              slot="back-button"
              text="Back domain list"
              data-testid="detail-back-button"
              onClick={() => navigate('/')}
            ></DidomiBackButtonLink>

            <div slot="header-title" className="ml-xs w-[300px]">
              <DidomiDropdown data-skeleton variant="blue" distance="xs" distance-x="l" selectedValue={selectedProperty?.name} data-testid="website-dropdown-selection">
                {listProperties?.map(property => (
                  <DidomiOption
                    itemText={getPropertyNameAndWebsite(property)}
                    itemSelected={property.id === selectedProperty?.id}
                    itemValue={property.id}
                    key={property.id}
                    onClick={() => handleSelectChange(property)}
                  ></DidomiOption>
                ))}
              </DidomiDropdown>
            </div>
          </DidomiSecondaryHeader>
        </div>
        {children}
      </section>
    </DidomiSkeleton>
  );
};
