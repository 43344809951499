import React from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiTableRow, DidomiTableTd } from '@didomi/ui-atoms-react';
import { TRANSLATED_SCENARIOS_MAP } from '@constants';
import { AggregatedTracker } from '@types';
import { getTrackerTypeValue } from '@utils';
import { TruncatedTextWithTooltip } from '../TruncatedTextWithTooltip/TruncatedTextWithTooltip';

interface ManageTrackerPolicyTableRowProps {
  trackers: AggregatedTracker[];
}

export const ManageTrackerPolicyTableRow = ({ trackers = [] }: ManageTrackerPolicyTableRowProps) => {
  return (
    <>
      {trackers.map((tracker, index) => (
        <DidomiTableRow data-testid={`tracker-row-${index}`} key={`tracker-${tracker.id}-${index}`} selectionValue={tracker.id}>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={`${tracker.name}`} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={tracker.party} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={tracker.initiator.label} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={tracker.vendor.label} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={getTrackerTypeValue(tracker.type)} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={TRANSLATED_SCENARIOS_MAP[tracker.functional_scenario]} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={tracker?.purposes?.length > 0 ? tracker?.purposes.map(purpose => getTranslatedValue(purpose.description)).join(', ') : 'None'} />
          </DidomiTableTd>
          <DidomiTableTd>
            <span className={`inline-block truncate w-full py-1`}>{tracker.is_exempt ? 'Yes' : 'No'}</span>
          </DidomiTableTd>
        </DidomiTableRow>
      ))}
    </>
  );
};
