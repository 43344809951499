import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse } from '@types';
import { NoticeTemplateVendor } from '../../../interfaces/notice-template-vendor.interface';

export const useNoticeTemplateVendors = (noticeTemplateId: string, options?: UseQueryOptions<PaginatedResponse<NoticeTemplateVendor>, AxiosError>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<NoticeTemplateVendor>>(`${apiRoutes.noticeTemplates}/${noticeTemplateId}/vendors`, {
      params: { organization_id: organizationId },
    });
    return response.data;
  };

  return useQuery<PaginatedResponse<NoticeTemplateVendor>, AxiosError>(['notice-template-vendors', { noticeTemplateId, organization_id: organizationId }], fetch, {
    ...options,
  });
};
