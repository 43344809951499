/**
 * Normalizes a URL by parsing it and removing 'www.' from the hostname
 * @param url The URL to normalize
 * @returns An object containing the normalized URL parts or null if invalid
 */
const normalizeUrl = (url: string): { url: URL; normalizedHostname: string } | null => {
  try {
    const parsedUrl = new URL(url);
    const normalizedHostname = parsedUrl.hostname.replace(/^www\./i, '');

    return { url: parsedUrl, normalizedHostname };
  } catch (e) {
    return null;
  }
};

/**
 * Validates if custom page is a subpage of the website
 * @param customPage The custom page URL
 * @param websiteUrl The website URL
 * @returns An object with validation result and error message
 */
export const validateCustomPage = (customPageUrl: string, websiteUrl: string, existingCustomPageUrls: string[]): { isValid: boolean; errorMessage?: string } => {
  if (!customPageUrl) {
    return { isValid: true }; // Skip validation if custom page url is not provided
  }

  if (!websiteUrl) {
    return { isValid: false, errorMessage: 'Website must be specified, before adding a specified page' };
  }

  if (existingCustomPageUrls.includes(customPageUrl.trim())) {
    return { isValid: false, errorMessage: 'Specific page URL already in the list' };
  }

  const websiteNormalized = normalizeUrl(websiteUrl);

  if (!websiteNormalized) {
    return {
      isValid: false,
      errorMessage: `Website is not a valid URL`,
    };
  }

  const { url: websiteUrlObj, normalizedHostname: normalizedWebsiteHostname } = websiteNormalized;

  const pageNormalized = normalizeUrl(customPageUrl);

  if (!pageNormalized) {
    return {
      isValid: false,
      errorMessage: `The provided specific page URL is not valid`,
    };
  }

  const { url: pageUrl, normalizedHostname: normalizedPageHostname } = pageNormalized;

  // Check if domains match (ignoring protocol and www.)
  if (normalizedPageHostname !== normalizedWebsiteHostname) {
    return {
      isValid: false,
      errorMessage: `The specific page URL must be on the same domain as the website`,
    };
  }

  // Check if page is a subpage of the website
  // Compare paths to see if the page path starts with the website path
  const websitePath = websiteUrlObj.pathname.endsWith('/') ? websiteUrlObj.pathname : `${websiteUrlObj.pathname}/`;
  const pagePath = pageUrl.pathname;

  if (!pagePath.startsWith(websitePath) && pagePath !== websiteUrlObj.pathname) {
    return {
      isValid: false,
      errorMessage: `The specific page URL must be a subpage of the website`,
    };
  }

  // Check if page is not the same as website
  if (pageUrl.pathname === websiteUrlObj.pathname) {
    return {
      isValid: false,
      errorMessage: `The specific page URL should not be the same as the website URL`,
    };
  }

  return { isValid: true };
};
