import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, useQueryClient } from 'react-query';
import { ReportOutputKey } from '@enums';
import { AggregatedTracker, PrivacyPolicy, VendorGraphEdge, VendorGraphNode } from '@types';
import { AggregatedVendor } from 'interfaces';
import { useAxiosWithLogOut } from '../../useAxiosWithInterceptors.hook';

type ReportOutputMap = {
  [ReportOutputKey.AGGREGATED_VENDORS]: AggregatedVendor[];
  [ReportOutputKey.AGGREGATED_TRACKERS]: AggregatedTracker[];
  [ReportOutputKey.AGGREGATED_VENDORS_GRAPH_EDGES]: VendorGraphEdge[];
  [ReportOutputKey.AGGREGATED_VENDORS_GRAPH_NODES]: VendorGraphNode[];
  [ReportOutputKey.PRIVACY_POLICY]: PrivacyPolicy[];
};

type UseS3OutputOptions = UseQueryOptions<ReportOutputMap[ReportOutputKey], AxiosError> & {};

export const useS3Output = (reportId: string, key: ReportOutputKey, url: string, options: UseS3OutputOptions = {}) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const fetchS3Output = async (url: string): Promise<ReportOutputMap[ReportOutputKey]> => {
    const response = await axiosWithInterceptors.get<ReportOutputMap[ReportOutputKey]>(url, {
      headers: {
        Authorization: undefined,
        'Content-Type': 'application/json',
      },
      params: {},
    });

    return response.data;
  };

  const fetch = (url: string) => queryClient.fetchQuery<ReportOutputMap[ReportOutputKey], AxiosError>(['report-output', reportId, key], () => fetchS3Output(url));

  const results = useQuery<ReportOutputMap[ReportOutputKey], AxiosError>(['report-output', reportId, key], () => fetchS3Output(url), options);

  return {
    ...results,
    fetch,
  };
};
