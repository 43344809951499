import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { NoticeTemplate } from '../../../interfaces/notice-template.interface';
import { PatchNoticeTemplatePayload } from '../../../types/payloads/patchNoticeTemplatePayload';

export const usePatchNoticeTemplate = (id: string, options?: UseMutationOptions<AxiosResponse<NoticeTemplate>, AxiosError, PatchNoticeTemplatePayload>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const patch = async (payload: PatchNoticeTemplatePayload) => {
    return await axiosWithInterceptors.patch<NoticeTemplate>(`${apiRoutes.noticeTemplates}/${id}`, payload, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation<AxiosResponse<NoticeTemplate>, AxiosError, PatchNoticeTemplatePayload>(patch, {
    ...options,
    onSuccess: (deletedEntity: AxiosResponse<NoticeTemplate>, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['notice-templates', { organization_id: organizationId }] });

      if (options?.onSuccess) {
        options.onSuccess(deletedEntity, variables, context);
      }
    },
  });
};
