import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { NoticeTemplate } from '../../../interfaces/notice-template.interface';
import { DeleteNoticeTemplatePayload } from '../../../types/payloads/deleteNoticeTemplatePayload';

export const useDeleteNoticeTemplate = (options?: UseMutationOptions<AxiosResponse<NoticeTemplate>, AxiosError, DeleteNoticeTemplatePayload>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const remove = async (payload: DeleteNoticeTemplatePayload) => {
    return await axiosWithInterceptors.delete<NoticeTemplate>(`${apiRoutes.noticeTemplates}/${payload.id}`, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation<AxiosResponse<NoticeTemplate>, AxiosError, DeleteNoticeTemplatePayload>(remove, {
    ...options,
    onSuccess: (deletedEntity: AxiosResponse<NoticeTemplate>, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['notice-templates', { organization_id: organizationId }] });

      if (options?.onSuccess) {
        options.onSuccess(deletedEntity, variables, context);
      }
    },
  });
};
