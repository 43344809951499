import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, PropertyTrackerItem } from '@types';

type UsePropertyTrackerSettingsArgs = {
  propertyId?: string;
  enrichOnly?: boolean;
  reportId?: string;
  enabled?: boolean;
};

export const usePropertyCookiePolicies = ({ propertyId, reportId, enabled = true, enrichOnly = false }: UsePropertyTrackerSettingsArgs = {}) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const fetchPropertyCookiePolicies = async (propertyId: string, reportId: string) => {
    if (propertyId) {
      const response = await axiosWithInterceptors.get<PaginatedResponse<PropertyTrackerItem>>(`${apiRoutes.crProperties}/${propertyId}/cookie-policies`, {
        params: {
          organization_id: organizationId,
          ...(reportId && { report_id: reportId }),
          ...(enrichOnly && { enrich_only: true }),
        },
      });
      return response.data;
    }
  };

  const query = useQuery<PaginatedResponse<PropertyTrackerItem>, AxiosError>(
    ['property-cookie-policies', propertyId, reportId],
    () => fetchPropertyCookiePolicies(propertyId, reportId),
    {
      refetchInterval: false,
      enabled,
    },
  );

  const fetch = (propertyId: string, reportId?: string) =>
    queryClient.fetchQuery<PaginatedResponse<PropertyTrackerItem>, AxiosError>(['property-cookie-policies', propertyId, reportId], () =>
      fetchPropertyCookiePolicies(propertyId, reportId),
    );

  return {
    ...query,
    fetch,
  };
};
