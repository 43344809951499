import { useMemo } from 'react';
import { useFeatureFlag } from '@didomi/utility-react';
import { useOrganizationQuotas } from './useOrganizationQuotas.hook';
import { SCRAPER_ENABLED_PROPERTIES_QUOTA } from '../consts';

type UseVerifyScrapingQuotaOptions = {
  totalProperties: number;
};

export const useVerifyScrapingQuota = ({ totalProperties }: UseVerifyScrapingQuotaOptions) => {
  // fetch organization scraping quota data
  const { data: organizationQuota, isLoading } = useOrganizationQuotas();

  // check if the compliance report premium feature is enabled
  const [hasComplianceReportPremiumFeature] = useFeatureFlag('compliance_report_v2');

  // determine the quota limit or fallback to 250 as default
  const scrapingQuota = organizationQuota?.scraper_enabled_properties ?? SCRAPER_ENABLED_PROPERTIES_QUOTA;
  // determine if the quota limit has been exceeded
  const isQuotaExceeded = useMemo(
    () => !hasComplianceReportPremiumFeature && totalProperties >= scrapingQuota,
    [totalProperties, scrapingQuota, hasComplianceReportPremiumFeature],
  );

  return {
    isLoading,
    isQuotaExceeded,
    scrapingQuota,
  };
};
