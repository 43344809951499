import React, { useState } from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiIconButton, DidomiLoader, DidomiModal, DidomiModalContent, DidomiTableRow, DidomiTableTd, DidomiTooltip } from '@didomi/ui-atoms-react';
import { useSnackbar, useSPARouter } from '@didomi/utility-react';
import { formatDate } from '@utils';
import { useDeleteNoticeTemplate } from '../../hooks/data/notice-templates/useDeleteNoticeTemplate.hook';
import { NoticeTemplate } from '../../interfaces/notice-template.interface';
import { DeleteNoticeTemplateModal } from '../../modals/DeleteNoticeTemplateModal/DeleteNoticeTemplateModal';
import { TextWithHighlighterAndTooltip } from '../TextWithHighlighterAndTooltip/TextWithHighlighterAndTooltip';

interface Props {
  noticeTemplates: NoticeTemplate[];
  search: string;
}

const ListVendorTemplatesTableRow = ({ noticeTemplates, search }: Props) => {
  const { navigateTo } = useSPARouter();
  const { displaySnackbar } = useSnackbar();

  const [isDeletingNoticeTemplateModal, setIsDeletingNoticeTemplateModal] = useState<string>();

  const { mutateAsync: deleteNoticeTemplate, isLoading: isDeletingNoticeTemplate } = useDeleteNoticeTemplate({
    onSuccess: ({ data: noticeTemplate }) => {
      if (noticeTemplate) {
        displaySnackbar('Deleted notice template!');
      }
    },
  });

  const handleDeleteNoticeTemplate = (id: string) => {
    displaySnackbar('Deleting notice template...');
    deleteNoticeTemplate({ id });
    setIsDeletingNoticeTemplateModal(undefined);
  };

  return (
    <>
      {noticeTemplates.map(noticeTemplate => {
        return (
          <DidomiTableRow data-skeleton key={noticeTemplate.id}>
            <DidomiTableTd>
              <TextWithHighlighterAndTooltip searchText={search} text={getTranslatedValue(noticeTemplate.name, '-')} />
            </DidomiTableTd>
            <DidomiTableTd>{formatDate(noticeTemplate.created_at)}</DidomiTableTd>
            <DidomiTableTd>{formatDate(noticeTemplate.updated_at)}</DidomiTableTd>
            <DidomiTableTd cellAlign="right" style={{ maxWidth: 350 }}>
              <div className="flex">
                <DidomiTooltip placement="top" distance="xxxs" content="Edit template">
                  <DidomiIconButton
                    data-tracking="acm-edit-template"
                    data-testid={`edit-${noticeTemplate.id}`}
                    icon="edit"
                    variant="rounded"
                    aria-label="Edit template"
                    className="mr-xs"
                    onClick={() => navigateTo(`/agnostik-compliance-report/manage-vendor-templates/${noticeTemplate.id}`)}
                    disabled={false}
                  />
                </DidomiTooltip>
                <DidomiTooltip placement="top" distance="xxxs" content="Delete template">
                  <DidomiIconButton
                    data-tracking="acm-delete-template"
                    data-testid={`delete-${noticeTemplate.id}`}
                    icon="delete"
                    variant="rounded"
                    aria-label="Delete template"
                    className="mr-xs"
                    onClick={() => setIsDeletingNoticeTemplateModal(noticeTemplate.id)}
                    disabled={false}
                  />
                </DidomiTooltip>
              </div>
            </DidomiTableTd>
          </DidomiTableRow>
        );
      })}
      <DeleteNoticeTemplateModal
        isOpen={!!isDeletingNoticeTemplateModal}
        onCancel={() => setIsDeletingNoticeTemplateModal(undefined)}
        onProceed={() => handleDeleteNoticeTemplate(isDeletingNoticeTemplateModal)}
      />
      <DidomiModal isOpen={isDeletingNoticeTemplate}>
        <DidomiLoader></DidomiLoader>
        <DidomiModalContent className="mt-m">{'We are deleting your vendor template ...'}</DidomiModalContent>
      </DidomiModal>
    </>
  );
};

export { ListVendorTemplatesTableRow };
