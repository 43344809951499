import React, { useMemo, useState } from 'react';
import { DidomiChip, DidomiCollapse, DidomiIcon, DidomiModal, DidomiModalContent, DidomiModalHeader } from '@didomi/ui-atoms-react';
import { TRANSLATED_SCENARIOS_MAP } from '@constants';
import { ReportOutputKey } from '@enums';
import { useReportOutput, usePropertyCookiePolicies } from '@hooks';
import { AggregatedVendor, PropertyTrackerItem, Report, VendorGraphNode } from '@types';
import { computeFunctionalScenario, ellipsis, getTrackerAgeInMonthOrDays } from '@utils';
import './modal-css-overwrite.css';

interface ModalProps {
  isOpen: boolean;
  displayOnlyName?: boolean;
  report: Report;
  vendorId: string;
  onClose: () => void;
  onVendorChange: (id: string) => void;
}

export const InitiatorDetailModal = ({ isOpen, displayOnlyName, report, vendorId, onVendorChange, onClose }: ModalProps) => {
  const [expandVendorDetails, setExpandVendorDetails] = useState(true);
  const [expandPrivacyPolicy, setExpandPrivacyPolicy] = useState(false);
  const [expandVendorOwnedDomain, setExpandVendorOwnedDomain] = useState(false);
  const [expandDroppedTrackers, setExpandDroppedTrackers] = useState(false);
  const [expandRequestedByVendors, setExpandRequestedByVendors] = useState(false);
  const [expandInitiatedToVendors, setExpandInitiatedToVendors] = useState(false);

  const { data: vendorsGraphNodes } = useReportOutput<VendorGraphNode>(report?.id, ReportOutputKey.AGGREGATED_VENDORS_GRAPH_NODES);
  const { data: aggregatedVendors } = useReportOutput<AggregatedVendor>(report?.id, ReportOutputKey.AGGREGATED_VENDORS);
  const { data: cookiePolicies } = usePropertyCookiePolicies({ propertyId: report?.property_id, reportId: report?.id });

  /**
   * Groups the cookie policies by name
   */
  const cookiePoliciesByName = useMemo(() => {
    if (!cookiePolicies?.data) {
      return new Map<string, PropertyTrackerItem>();
    }

    return cookiePolicies.data.reduce((acc, cookie) => {
      acc.set(String(cookie.name), cookie);
      return acc;
    }, new Map<string, PropertyTrackerItem>());
  }, [cookiePolicies]);

  /**
   * Finds the selected vendor
   */
  const selectedVendor: VendorGraphNode = useMemo(() => {
    return vendorsGraphNodes?.find(node => node.id === vendorId);
  }, [vendorsGraphNodes, vendorId]);

  /**
   * Finds the domains found during scan
   */
  const domainsFoundDuringScan = useMemo(() => {
    if (!aggregatedVendors?.length) {
      return new Set<string>();
    }

    const selectedAggregatedVendor = aggregatedVendors.find(vendor => vendor.id === vendorId);
    if (!selectedAggregatedVendor) {
      return new Set<string>();
    }

    return new Set(selectedAggregatedVendor?.partner?.matched_hosts?.map(host => host.raw_pattern));
  }, [aggregatedVendors, vendorId]);

  /**
   * Handles the vendor change
   */
  const handleVendorChange = (id: string) => {
    // Ensure state reset before setting new ID when the modal is opened
    setExpandPrivacyPolicy(false);
    setExpandVendorOwnedDomain(false);
    setExpandDroppedTrackers(false);
    setExpandRequestedByVendors(false);
    setExpandInitiatedToVendors(false);
    onVendorChange(id);
  };

  return (
    <div>
      <DidomiModal
        isOpen={isOpen}
        variant="main"
        closable={true}
        onOpenChange={evt => {
          if (evt.type === 'openChange' && !evt.detail) {
            onClose();
          }
        }}
      >
        <DidomiModalHeader modal-title={selectedVendor?.label || selectedVendor?.id}></DidomiModalHeader>
        <DidomiModalContent className="w-full">
          {/** Vendor details */}
          {!displayOnlyName && (
            <>
              <button
                id="panel-1-toggle"
                onClick={() => setExpandVendorDetails(!expandVendorDetails)}
                className="border border-2 flex flex-row items-center agnostik-modal text-primary-blue-6 font-semibold item-box"
              >
                <div className="panel-text">Vendor details</div>
                <div className="down-arrow">
                  <DidomiIcon name="down-on" className="ml-auto" />
                </div>
              </button>
              <DidomiCollapse id="panel-1" is-expanded={expandVendorDetails} className="border border-2 mb-xs no-top-border">
                <ul className="text-left text-body-small text-primary-blue-6" style={{ padding: '16px' }}>
                  <li className="mb-m flex items-center">
                    <span>Vendor in TCF :</span>
                    {selectedVendor?.namespaces && selectedVendor?.namespaces['iab2'] ? (
                      <div className="flex items-center">
                        <DidomiIcon name="success-medium" className="ml-xs" />
                        <span className="ml-xs">
                          <DidomiChip label={`ID #${selectedVendor?.namespaces['iab2']}`} variation="basic" />
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <DidomiIcon name="failure-medium" className="ml-xs" />
                        <span className="ml-xs">
                          <DidomiChip label="Unknown" variation="basic-outline" basic-type="empty"></DidomiChip>
                        </span>
                      </div>
                    )}
                  </li>
                </ul>
              </DidomiCollapse>
            </>
          )}

          {/** Privacy policy links */}
          {selectedVendor?.links?.privacypolicy && (
            <>
              <button
                id="panel-2-toggle"
                onClick={() => setExpandPrivacyPolicy(!expandPrivacyPolicy)}
                className="border border-2 flex flex-row items-center agnostik-modal text-primary-blue-6 font-semibold item-box"
              >
                <div className="panel-text">Privacy Policy</div>
                <div className="down-arrow">
                  <DidomiIcon name="down-on" className="ml-auto" />
                </div>
              </button>
              <DidomiCollapse id="panel-2" is-expanded={expandPrivacyPolicy} className="border border-2 mb-xs no-top-border">
                <div className="w-full text-center text-primary-blue-6 underline privacy-policy-link-box">
                  <a href={selectedVendor?.links?.privacypolicy} target="_blank" rel="noopener noreferrer">
                    {selectedVendor?.links?.privacypolicy}
                  </a>
                </div>
              </DidomiCollapse>
            </>
          )}

          {/** Domains owned by vendor */}
          {selectedVendor?.hosts?.length > 0 && (
            <>
              <button
                id="panel-3-toggle"
                onClick={() => (selectedVendor?.hosts?.length ? setExpandVendorOwnedDomain(!expandVendorOwnedDomain) : undefined)}
                className="border border-2 flex flex-row items-center agnostik-modal text-primary-blue-6 font-semibold item-box"
                style={{ cursor: selectedVendor?.hosts?.length ? 'pointer' : 'inherit' }}
              >
                <div className="panel-text">{`Domains owned by the vendor: ${selectedVendor?.hosts?.length}`}</div>
                {!!selectedVendor?.hosts?.length && (
                  <div className="down-arrow">
                    <DidomiIcon name="down-on" className="ml-auto" />
                  </div>
                )}
              </button>
              <DidomiCollapse id="panel-3" is-expanded={expandVendorOwnedDomain} className="border border-2 mb-xs no-top-border">
                <div className="vendor-owned-domains-box">
                  {(Array.isArray(selectedVendor?.hosts) ? selectedVendor.hosts : [selectedVendor?.hosts]).flat().map(domain => {
                    return (
                      <span key={domain} className="domain-chip-wrapper">
                        <DidomiChip label={domain} basic-type="neutral" variation={domainsFoundDuringScan.has(domain) ? 'basic' : 'basic-outline'} />
                      </span>
                    );
                  })}
                </div>
              </DidomiCollapse>
            </>
          )}

          {/** Dropped trackers */}
          <button
            id="panel-4-toggle"
            onClick={() => (selectedVendor?.initiated_trackers?.length ? setExpandDroppedTrackers(!expandDroppedTrackers) : null)}
            className="border border-2 flex flex-row items-center agnostik-modal text-primary-blue-6 font-semibold item-box"
            style={{ cursor: selectedVendor?.initiated_trackers?.length ? 'pointer' : 'inherit' }}
          >
            <div className="panel-text">{`Dropped trackers: ${selectedVendor?.initiated_trackers?.length}`}</div>
            {!!selectedVendor?.initiated_trackers?.length && (
              <div className="down-arrow">
                <DidomiIcon name="down-on" className="ml-auto" />
              </div>
            )}
          </button>

          <DidomiCollapse id="panel-4" is-expanded={expandDroppedTrackers} className="border border-2 mb-xs no-top-border">
            <div className="text-body-small text-primary-blue-6">
              <div className="flex w-full justify-between justify-items-stretch content-center text-left font-bold" style={{ padding: '12px', borderBottom: '1px solid' }}>
                <div className="w-full">Name</div>
                <div className="w-full">Type</div>
                <div className="w-full">Lifetime</div>
                <div className="w-full">User behaviour</div>
              </div>
              {selectedVendor?.initiated_trackers?.map((tracker: string, idx: number) => {
                return (
                  <div key={idx} className="flex w-full justify-between text-left" style={{ padding: '12px', borderBottom: '1px solid' }}>
                    <div className="w-full break-all" style={{ wordBreak: 'break-all' }}>
                      {ellipsis(tracker, 15)}
                    </div>
                    <div className="w-full">{cookiePoliciesByName.get(tracker)?.type}</div>
                    <div className="w-full">{getTrackerAgeInMonthOrDays(cookiePoliciesByName.get(tracker)?.max_lifetime_seconds)}</div>
                    <div className="w-full">{TRANSLATED_SCENARIOS_MAP[computeFunctionalScenario(cookiePoliciesByName.get(tracker)?.cmp?.scenario?.id)]}</div>
                  </div>
                );
              })}
            </div>
          </DidomiCollapse>

          {/** Requested by */}
          <button
            id="panel-5-toggle"
            onClick={() => (selectedVendor?.initiated_from?.length ? setExpandRequestedByVendors(!expandRequestedByVendors) : null)}
            className="border border-2 flex flex-row items-center agnostik-modal text-primary-blue-6 font-semibold item-box"
            style={{ cursor: selectedVendor?.initiated_from?.length ? 'pointer' : 'inherit' }}
          >
            <div className="panel-text">{`Requested by ${selectedVendor?.initiated_from?.length} vendors`}</div>
            {!!selectedVendor?.initiated_from?.length && (
              <div className="down-arrow">
                <DidomiIcon name="down-on" className="ml-auto" />
              </div>
            )}
          </button>

          <DidomiCollapse id="panel-5" is-expanded={expandRequestedByVendors} className="border border-2 mb-xs no-top-border">
            {selectedVendor?.initiated_from?.map((key, idx) => {
              return selectedVendor?.id !== key ? (
                <button
                  key={idx}
                  className="w-full text-center text-primary-blue-6"
                  style={{
                    padding: '16px',
                    borderBottom: '1px solid',
                    cursor: !key ? 'inherit' : key === selectedVendor?.id ? 'inherit' : 'pointer',
                  }}
                  onClick={() => {
                    handleVendorChange(key);
                  }}
                >
                  {vendorsGraphNodes?.find(node => node.id === key)?.label}
                </button>
              ) : (
                <div
                  key={idx}
                  className="w-full text-center text-primary-blue-6"
                  style={{
                    padding: '16px',
                    borderBottom: '1px solid',
                    cursor: !key ? 'inherit' : key === selectedVendor?.id ? 'inherit' : 'pointer',
                  }}
                >
                  {vendorsGraphNodes?.find(node => node.id === key)?.label}
                </div>
              );
            })}
          </DidomiCollapse>

          {/** Initiated request to */}
          <button
            id="panel-6-toggle"
            onClick={() => (selectedVendor?.initiated_to?.length ? setExpandInitiatedToVendors(!expandInitiatedToVendors) : null)}
            className="border border-2 flex flex-row items-center agnostik-modal text-primary-blue-6 font-semibold item-box"
            style={{ cursor: selectedVendor?.initiated_to?.length ? 'pointer' : 'inherit' }}
          >
            <div className="panel-text">{`Initiated request to ${selectedVendor?.initiated_to?.length} vendors`}</div>
            {!!selectedVendor?.initiated_to?.length && (
              <div className="down-arrow">
                <DidomiIcon name="down-on" className="ml-auto" />
              </div>
            )}
          </button>

          <DidomiCollapse id="panel-6" is-expanded={expandInitiatedToVendors} className="border border-2 no-top-border">
            {selectedVendor?.initiated_to?.map((key, idx) => {
              return selectedVendor?.id !== key ? (
                <button
                  key={idx}
                  className="w-full text-center text-primary-blue-6 underline"
                  style={{ padding: '16px', borderBottom: '1px solid', cursor: key === selectedVendor?.id ? 'inherit' : 'pointer' }}
                  onClick={() => {
                    handleVendorChange(key);
                  }}
                >
                  {vendorsGraphNodes?.find(node => node.id === key)?.label}
                </button>
              ) : (
                <div
                  key={idx}
                  className="w-full text-center text-primary-blue-6 underline"
                  style={{ padding: '16px', borderBottom: '1px solid', cursor: key === selectedVendor?.id ? 'inherit' : 'pointer' }}
                >
                  {vendorsGraphNodes?.find(node => node.id === key)?.label}
                </div>
              );
            })}
          </DidomiCollapse>
        </DidomiModalContent>
      </DidomiModal>
    </div>
  );
};
