import React, { useState } from 'react';
import { DidomiModal, DidomiButton, DidomiModalHeader, DidomiModalContent, DidomiTextInputField, DidomiCheckbox } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AggregatedTracker } from '@types';

const editTrackerSchema = Yup.object().shape({
  name: Yup.string().trim().required('Tracker name is required'),
});

interface EditTrackerModalProps {
  isOpen: boolean;
  tracker: AggregatedTracker;
  onClose: (value: boolean) => void;
  onSubmit: (data: AggregatedTracker) => void;
}

export const EditTrackerModal = ({ isOpen, tracker, onClose, onSubmit }: EditTrackerModalProps) => {
  const [isNameRegexPattern, setIsNameRegexPattern] = useState(false);

  const handleDialogChange = (e: CustomEvent) => {
    if (isOpen && !e.detail) {
      onClose(e.detail);
    }
  };

  const handleSubmit = (data: Partial<AggregatedTracker>) => {
    return onSubmit({
      ...tracker,
      name: data.name,
      is_regex: isNameRegexPattern,
    });
  };

  return (
    <div>
      <DidomiModal id="modal" variant="slim" isOpen={isOpen} onOpenChange={handleDialogChange} data-testid="ValidationCreateNewTrackerModal" closable>
        <Formik enableReinitialize initialValues={tracker} validationSchema={editTrackerSchema} onSubmit={handleSubmit} validateOnMount={true}>
          {({ values, submitForm }) => (
            <>
              <DidomiModalHeader modal-sub-title="Edit tracker" modal-description="You can define a RegEx to match starting from an existing tracker name."></DidomiModalHeader>
              <DidomiModalContent>
                <Form className="w-full max-w-[700px] flex flex-col mb-s">
                  <div>
                    <DidomiTextInputField name="name" label="Tracker name" placeholder="eg. tracker_.*" required />
                    <div className={'text-left'}>
                      <DidomiCheckbox label="Tracker name is a RegEx" checked={values?.is_regex || isNameRegexPattern} onValueChange={e => setIsNameRegexPattern(e.detail)} />
                    </div>
                    <DidomiTextInputField name="party" label="Party" placeholder="Party" required disabled />
                    <DidomiTextInputField name="initiator.label" label="Initiator" placeholder="Initiator" required disabled />
                    <DidomiTextInputField name="type" label="Type" placeholder="Type" required disabled />

                    <DidomiButton size="medium" variant="secondary" className={tx('mb-2.5')} full-width onClick={() => onClose(false)}>
                      Cancel
                    </DidomiButton>
                    <DidomiButton size="medium" variant="main" full-width onClick={submitForm} data-testid="save-tracker" type="button">
                      Save
                    </DidomiButton>
                  </div>
                </Form>
              </DidomiModalContent>
            </>
          )}
        </Formik>
      </DidomiModal>
    </div>
  );
};
