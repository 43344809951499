import { useNavigate } from 'react-router-dom';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { filterSortPaginate } from '@didomi/helpers';
import {
  DidomiButton,
  DidomiFiltersBar,
  DidomiLoader,
  DidomiModal,
  DidomiModalContent,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableTh,
} from '@didomi/ui-atoms-react';
import { useFeatureFlag, useSnackbar, useSPARouter } from '@didomi/utility-react';
import { sanitizeHTML } from '@didomi/xss/sanitizers';
import { homeRoute } from '@constants';
import { SortConfig } from '@types';
import { ComplianceReportDetailTableEmptyRow } from '../../components/ComplianceReportDetail/ComplianceReportDetailTableEmptyRow';
import { ListVendorTemplatesTableRow } from '../../components/ManageVendorTemplates/ListVendorTemplatesTableRow';
import { useCreateNoticeTemplate } from '../../hooks/data/notice-templates/useCreateNoticeTemplate.hook';
import { useNoticeTemplates } from '../../hooks/data/notice-templates/useNoticeTemplates.hook';
import { usePaginationQueryParams } from '../../hooks/usePaginationQueryParams.hook';
import { SecondaryLayout } from '../../layouts/SecondaryLayout/SecondaryLayout';

type SortableFields = 'name' | 'created_at' | 'updated_at';

const SEARCH_FIELDS = [{ field: 'name' }];

const INITIAL_SORT: SortConfig<SortableFields> = { field: 'name', dir: 'asc' };

export const ListVendorTemplates = () => {
  const navigate = useNavigate();
  const { navigateTo } = useSPARouter();
  const { displaySnackbar } = useSnackbar();
  const [isAcmVendorTemplatesEnabled] = useFeatureFlag('acm_vendor_templates');

  useLayoutEffect(() => {
    if (!isAcmVendorTemplatesEnabled) {
      navigate(homeRoute);
    }
  }, [isAcmVendorTemplatesEnabled, navigate]);

  const [sortConfig, setSortConfig] = useState<SortConfig<SortableFields>>(INITIAL_SORT);

  const { isLoading: isFetchingNoticeTemplates, data: { data: fetchedNoticeTemplates } = {} } = useNoticeTemplates({ refetchOnMount: 'always' });
  const { mutateAsync: createNoticeTemplate, isLoading: isCreatingNoticeTemplate } = useCreateNoticeTemplate({
    onSuccess: ({ data: noticeTemplate }) => {
      if (noticeTemplate) {
        displaySnackbar('Vendor template has been successfully created!');
        navigateTo(`/agnostik-compliance-report/manage-vendor-templates/${noticeTemplate.id}`);
      }
    },
  });

  const { search, limit, page: currPage, setSearch, setLimit, setPage: setCurrPage } = usePaginationQueryParams({ defaultLimit: 20 });

  const { data: noticeTemplates, total } = useMemo(() => {
    return filterSortPaginate(fetchedNoticeTemplates, {
      search,
      searchFields: SEARCH_FIELDS,
      limit,
      page: currPage,
      sortConfig,
    });
  }, [currPage, limit, search, sortConfig, fetchedNoticeTemplates]);

  const setFirstPage = () => {
    setCurrPage(
      new CustomEvent<{ page: number }>('pageChange', {
        detail: {
          page: 1,
        },
      }),
    );
  };

  const handleSortChange = (e: CustomEvent) => {
    const newSortBy = e.detail.sortId;
    const newSortDir = newSortBy !== sortConfig.field ? 'desc' : e.detail.direction;
    setFirstPage();
    setSortConfig({ dir: newSortDir, field: newSortBy ?? sortConfig.field });
  };

  const handleCreateNoticeTemplate = async () => {
    await createNoticeTemplate({ name: sanitizeHTML('My Vendor Template').result });
  };

  const isPaginationEnabled = noticeTemplates.length > 0;

  const isLoading = isFetchingNoticeTemplates || isCreatingNoticeTemplate;

  return (
    <DidomiSkeleton isLoading={isLoading} variant="layout">
      <SecondaryLayout titleText={'Manage Vendor Templates'} backText={'Back to Compliance Reports'} backPath={homeRoute} fullWidth={true}>
        <div className="py-6 flex flex-col">
          <div className="flex justify-end items-center gap-xs">
            <DidomiFiltersBar
              data-testid="filter-bar"
              placeholderTextFilter="Search a template"
              showSearch
              searchValue={search}
              collapsibleFilters={false}
              onSearchTextChange={setSearch}
            />

            <DidomiButton data-testid="acm-create-vendor-template" onClick={() => handleCreateNoticeTemplate()} iconRight="new-create">
              Create a template
            </DidomiButton>
          </div>

          <DidomiTable sortable sortBy={sortConfig.field} sortDirection={sortConfig.dir} onSortChange={handleSortChange} style={{ width: 'calc(100% - 2px)', margin: '16px auto' }}>
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                <DidomiTableTh sortId="name">Name</DidomiTableTh>
                <DidomiTableTh sortId="created_at">Created date</DidomiTableTh>
                <DidomiTableTh sortId="updated_at">Updated date</DidomiTableTh>
                <DidomiTableTh></DidomiTableTh>
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody>
              {noticeTemplates.length > 0 && <ListVendorTemplatesTableRow noticeTemplates={noticeTemplates} search={search} />}
              {!isLoading && noticeTemplates.length === 0 && <ComplianceReportDetailTableEmptyRow />}
            </DidomiTableBody>
          </DidomiTable>

          {isPaginationEnabled && (
            <DidomiPaginator
              className="self-end mt-xs w-full justify-end flex"
              variant="normal"
              page={currPage}
              itemCount={total}
              size={limit}
              onPageSizeChange={setLimit}
              onPageChange={setCurrPage}
            />
          )}

          <DidomiModal isOpen={isCreatingNoticeTemplate}>
            <DidomiLoader></DidomiLoader>
            <DidomiModalContent className="mt-m">{'We are creating your vendor template ...'}</DidomiModalContent>
          </DidomiModal>
        </div>
      </SecondaryLayout>
    </DidomiSkeleton>
  );
};
