import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { NoticeTemplateVendor } from '../../../interfaces/notice-template-vendor.interface';
import { CreateNoticeTemplateVendorPayload } from '../../../types/payloads/createNoticeTemplateVendorPayload';

export const useCreateNoticeTemplateVendors = (
  noticeTemplateId: string,
  options?: UseMutationOptions<AxiosResponse<NoticeTemplateVendor[]>, AxiosError, CreateNoticeTemplateVendorPayload[]>,
) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const create = async (data: CreateNoticeTemplateVendorPayload[]) => {
    return await axiosWithInterceptors.post<NoticeTemplateVendor[]>(`${apiRoutes.noticeTemplates}/${noticeTemplateId}/vendors`, data, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation(create, {
    ...options,
    onSuccess: (createdEntities: AxiosResponse<NoticeTemplateVendor[]>, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['notice-template-vendors', { organization_id: organizationId }] });

      if (options?.onSuccess) {
        options.onSuccess(createdEntities, variables, context);
      }
    },
  });
};
