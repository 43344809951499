import { ReportStatus, ScenarioType } from '@enums';
import { AggregatedTracker, Property, AggregatedVendor, Report, Purpose, PropertyTrackerItem, PrivacyPolicy } from '@types';
import { defineVendorsTableData, defineUnkowVendors, defineTrackersTableDataOld, assignCookieIds, addExemptionStatusAndPurposesToCookies, defineTrackersTableData } from '@utils';
interface Error {
  name: string;
  type: 'info' | 'tracker-table' | 'vendor-table';
  warningLevel: 'warning' | 'alert';
  dataTable?: AggregatedVendor[] | AggregatedTracker[] | any[];
  expandId?: string;
  entities: string;
  extraLink: string | null;
  extralinkText: string | null;
}

const SECONDS_IN_MONTH = 2629746;
const THIRTEEN_MONTHS_IN_SECONDS = SECONDS_IN_MONTH * 13;

const trackersDroppedWithoutConsent = (trackers: AggregatedTracker[]) => {
  return trackers.filter(tracker => tracker?.functional_scenario === 'NO_USER_CHOICE');
};

const trackersDroppedDespiseRefuseAll = (trackers: AggregatedTracker[]) => {
  return trackers.filter(tracker => tracker?.functional_scenario === 'REFUSE_TO_ALL');
};

export const aggregateComplianceIssuesOld = (report: Report, purposes: Purpose[], propertyTrackerSettings: PropertyTrackerItem[]): Error[] => {
  if (!report || ![ReportStatus.SUCCESS, ReportStatus.PARTIAL].includes(report?.status as ReportStatus)) return [];
  const issues = [];
  const property = report?.details_json?.property as Property;
  const reportCookiesWithIds = assignCookieIds(report?.details_json.property.id, report?.details_json.property.cookies || []);
  const updatedCookies = addExemptionStatusAndPurposesToCookies(reportCookiesWithIds, purposes, propertyTrackerSettings, [], true);

  const trackers = defineTrackersTableDataOld(updatedCookies);
  const trackersWith13MonthsLifetime = trackers.filter(cookie => cookie.lifetime_in_months_numeric > 13);
  const vendorTableData = defineVendorsTableData(report);
  const unknowVendors = defineUnkowVendors(vendorTableData);
  const trackersDespiseRefuseAll = trackersDroppedDespiseRefuseAll(trackers);
  const trackersWithoutChoice = trackersDroppedWithoutConsent(trackers);

  if (trackersDespiseRefuseAll.length) {
    const error = {
      name: 'dropped despite user refusing consent.',
      entities: `${trackersDespiseRefuseAll.length} trackers`,
      type: 'tracker-table',
      warningLevel: 'warning',
      expandId: 'trackersDespiseRefuseAll',
      dataTable: trackersDespiseRefuseAll,
      extraLink: 'https://support.didomi.io/multi-scenario-scan',
      extralinkText: 'For more information.',
    } as Error;
    issues.push(error);
  }
  if (trackersWithoutChoice.length) {
    const error = {
      name: 'dropped despite user not making a choice.',
      entities: `${trackersWithoutChoice.length} trackers`,
      type: 'tracker-table',
      warningLevel: 'warning',
      expandId: 'trackersWithoutChoice',
      dataTable: trackersWithoutChoice,
      extraLink: 'https://support.didomi.io/multi-scenario-scan',
      extralinkText: 'For more information.',
    } as Error;
    issues.push(error);
  }
  if (!property.privacy.privacy_policy.present_on_all_pages) {
    const error = {
      name: 'The Privacy Policy is not accessible from every page of the website.',
      type: 'info',
      warningLevel: 'warning',
    } as Error;
    issues.push(error);
  }
  if (!property.privacy.privacy_policy.optout) {
    const error = {
      name: 'The privacy policy does not seem to provide a way to opt out',
      type: 'info',
      warningLevel: 'warning',
    } as Error;
    issues.push(error);
  }
  if (!property.privacy.user_consent.choice) {
    const error = {
      name: 'No CMP was detected on the website.',
      type: 'info',
      warningLevel: 'alert',
    } as Error;
    issues.push(error);
  }

  if (!property.privacy.user_consent.information) {
    const error = {
      name: 'The consent notice doesn’t provide information about purposes and vendors.',
      type: 'info',
      warningLevel: 'alert',
    } as Error;
    issues.push(error);
  }
  if (trackersWith13MonthsLifetime.length) {
    const error = {
      name: 'have lifetime longer than 13 months which is not recommended by GDPR.',
      entities: `${trackersWith13MonthsLifetime.length} trackers`,
      type: 'tracker-table',
      warningLevel: 'warning',
      dataTable: trackersWith13MonthsLifetime,
      expandId: 'lifetimeTrackers',
    } as Error;
    issues.push(error);
  }

  if (unknowVendors.length) {
    const error = {
      name: 'are unknown to our database, review them to be sure they are legit',
      entities: `${unknowVendors.length} vendors or initiators`,
      type: 'vendor-table',
      warningLevel: 'warning',
      dataTable: unknowVendors,
      expandId: 'unknowVendors',
    } as Error;
    issues.push(error);
  }
  if (!property.privacy.privacy_policy.present) {
    const error = {
      name: 'A Cookie Policy wasn’t found',
      type: 'info',
      warningLevel: 'warning',
    } as Error;
    issues.push(error);
  }

  return issues;
};

/**
 * A helper function to add an issue to the issues array
 */
const addIssue = (
  issues: Error[],
  name: string,
  entities: string,
  type: string,
  warningLevel: string,
  expandId: string,
  dataTable: any[],
  extraLink?: string,
  extralinkText?: string,
) => {
  issues.push({
    name,
    entities,
    type,
    warningLevel,
    expandId,
    dataTable,
    extraLink: extraLink || null,
    extralinkText: extralinkText || null,
  } as Error);
};

/**
 * Helper function to filter trackers by scenario type
 * @param trackers - The list of trackers to filter
 * @param scenario - The scenario type to filter by
 * @returns Filtered list of trackers matching the scenario type
 */
const getTrackersByScenarioType = (trackers: AggregatedTracker[], scenario: ScenarioType): AggregatedTracker[] => {
  return trackers.filter(cookie => (cookie.functional_scenario as any as ScenarioType) === scenario);
};

/**
 * Aggregate compliance issues for the new report
 * @param report - The report to aggregate the compliance issues from
 * @param privacyPolicy - The privacy policy to aggregate the compliance issues from
 * @param cookiePolicy - The cookie policy to aggregate the compliance issues from
 * @param vendors - The vendors to aggregate the compliance issues from
 */
export const aggregateComplianceIssues = (report: Report, privacyPolicy: PrivacyPolicy, cookiePolicies: PropertyTrackerItem[], vendors: AggregatedVendor[]): Error[] => {
  // Check if the report is valid
  const isReportValid = report && [ReportStatus.SUCCESS, ReportStatus.PARTIAL].includes(report?.status as ReportStatus);

  // If the data is not valid, return an empty array
  if (!cookiePolicies || !vendors || !privacyPolicy || !isReportValid) {
    return [];
  }

  const issues: Error[] = [];
  const trackers = defineTrackersTableData(cookiePolicies);

  const trackersWith13MonthsLifetime = trackers.filter(cookie => cookie.max_lifetime_seconds > THIRTEEN_MONTHS_IN_SECONDS);
  const unknownVendors = vendors.filter(vendor => !vendor.partner);

  const trackersRefuseAll = getTrackersByScenarioType(trackers, ScenarioType.REFUSE_TO_ALL);
  const trackersNoUserChoice = getTrackersByScenarioType(trackers, ScenarioType.NO_USER_CHOICE);

  // Add an issue if there are trackers that dropped despite user refusing consent
  if (trackersRefuseAll.length) {
    addIssue(
      issues,
      'dropped despite user refusing consent.',
      `${trackersRefuseAll.length} trackers`,
      'tracker-table',
      'warning',
      'trackersRefuseAll',
      trackersRefuseAll,
      'https://support.didomi.io/multi-scenario-scan',
      'For more information.',
    );
  }

  // Add an issue if there are trackers that dropped despite user not making a choice
  if (trackersNoUserChoice.length) {
    addIssue(
      issues,
      'dropped despite user not making a choice.',
      `${trackersNoUserChoice.length} trackers`,
      'tracker-table',
      'warning',
      'trackersNoUserChoice',
      trackersNoUserChoice,
      'https://support.didomi.io/multi-scenario-scan',
      'For more information.',
    );
  }

  // Add an issue if there are trackers that have a lifetime longer than 13 months
  if (trackersWith13MonthsLifetime.length) {
    addIssue(
      issues,
      'have lifetime longer than 13 months which is not recommended by GDPR.',
      `${trackersWith13MonthsLifetime.length} trackers`,
      'tracker-table',
      'warning',
      'lifetimeTrackers',
      trackersWith13MonthsLifetime,
    );
  }

  const privacyChecks = [
    {
      condition: !privacyPolicy.privacy.has_privacy_policy_all_pages,
      name: 'The Privacy Policy is not accessible from every page of the website.',
      type: 'info',
      warningLevel: 'warning',
    },
    { condition: !privacyPolicy.privacy.has_privacy_policy_opt_out, name: 'The privacy policy does not seem to provide a way to opt out', type: 'info', warningLevel: 'warning' },
    { condition: !privacyPolicy.privacy.has_user_consent_choice, name: 'No CMP was detected on the website.', type: 'info', warningLevel: 'alert' },
    {
      condition: !privacyPolicy.privacy.has_user_consent_information,
      name: 'The consent notice doesn’t provide information about purposes and vendors.',
      type: 'info',
      warningLevel: 'alert',
    },
    { condition: !privacyPolicy.privacy.has_privacy_policy, name: 'A Cookie Policy wasn’t found', type: 'info', warningLevel: 'warning' },
  ];

  // For each privacy check, if the condition is satisfied, add the issue
  privacyChecks.forEach(check => {
    if (check.condition) {
      issues.push({
        name: check.name,
        type: check.type,
        warningLevel: check.warningLevel,
      } as Error);
    }
  });

  // Add an issue if there are unknown vendors
  if (unknownVendors.length) {
    addIssue(
      issues,
      'are unknown to our database, review them to be sure they are legit',
      `${unknownVendors.length} vendors or initiators`,
      'vendor-table',
      'warning',
      'unknownVendors',
      unknownVendors,
    );
  }

  return issues;
};
