import React, { useCallback, useMemo, useState } from 'react';
import { filterSortPaginate } from '@didomi/helpers';
import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';
import { DidomiFiltersBar, DidomiPaginator, DidomiTable, DidomiTableBody, DidomiTableHeaderRow, DidomiTableHeading, DidomiTableTh } from '@didomi/ui-atoms-react';
import { ComplianceReportDetailTableVendorRow } from '@components';
import { ReportOutputKey, ScenarioType, TCFStatusType, VendorLegalBasisFilterKey } from '@enums';
import { useTableState, useReportOutput } from '@hooks';
import { InitiatorDetailModal } from '@modals';
import { AggregatedVendor, Report, SortConfig } from '@types';
import { computeFunctionalScenario, filterByLegalBasis, filterVendorByTCFStatus, filterVendorsByFunctionalScenario, legalBasisFrom } from '@utils';
import { ComplianceReportDetailTableEmptyRow } from './ComplianceReportDetailTableEmptyRow';
import { vendorsFilters } from './filters';

interface Props {
  report?: Report;
  hideTableLabel?: boolean;
  hideTableFilter?: boolean;
  displayOnlyName?: boolean;
  isLoadingNodes?: boolean;
  predefinedVendors?: AggregatedVendor[];
}
type SortableFields = 'label' | 'tcf_id' | 'legal_basis' | 'request_count' | 'functional_scenario';

type Filter = {
  tcfStatus?: TCFStatusType[];
  legalBasis?: VendorLegalBasisFilterKey[];
  functionalScenario?: ScenarioType[];
};

type FilterChangeType = { key: string; newValue: string };

type FilterMatch = (vendor: AggregatedVendor, search?: Filter[keyof Filter]) => boolean;

const SEARCH_FIELDS = [{ field: 'label' }];

const FILTER_MAP: Record<keyof Filter, FilterMatch> = {
  tcfStatus: filterVendorByTCFStatus,
  legalBasis: filterByLegalBasis,
  functionalScenario: filterVendorsByFunctionalScenario,
};

const INITIAL_SORT: SortConfig<SortableFields> = { field: 'label', dir: 'asc' };

const ComplianceReportCoreVendorTable = ({
  report,
  hideTableLabel = false,
  hideTableFilter = false,
  displayOnlyName = false,
  isLoadingNodes = false,
  predefinedVendors,
}: Props) => {
  const [sortConfig, setSortConfig] = useState<SortConfig<SortableFields>>(INITIAL_SORT);
  const [filter, setFilter] = useState<Filter>({});
  const [vendorPreviewId, setVendorPreviewId] = useState<string | null>(null);

  const { data: aggregatedVendors, isLoading: isLoadingAggregatedVendors } = useReportOutput<AggregatedVendor>(report?.id, ReportOutputKey.AGGREGATED_VENDORS);

  const isLoading = isLoadingNodes || isLoadingAggregatedVendors;

  // Enriched vendors
  const vendors = useMemo(() => {
    if (!aggregatedVendors && !predefinedVendors) return [];

    // If the predefined vendors are available, use them as a first priority
    const vendors = predefinedVendors || aggregatedVendors;

    return vendors.map(vendor => ({
      ...vendor,
      label: vendor.partner?.name || vendor.id, // needed for search
      tcf_id: vendor.partner?.namespaces?.iab2 || 'Not in TCF', // needed for sorting
      legal_basis: legalBasisFrom(vendor?.partner?.tcf?.default_purposes_id, vendor?.partner?.tcf?.legitimate_interest_purposes_id), // needed for sorting
      functional_scenario: computeFunctionalScenario(vendor?.cmp?.scenario?.id as ScenarioType[]), // needed for sorting
    }));
  }, [aggregatedVendors, predefinedVendors]);

  const generatedVendorsFilters = useMemo(() => {
    return vendors && vendorsFilters(vendors);
  }, [vendors]);

  const executeFilter = useCallback(
    (vendor: AggregatedVendor): boolean => {
      return Object.entries(filter).every(([filterKey, filterValue]) => FILTER_MAP[filterKey](vendor, filterValue));
    },
    [filter],
  );

  const {
    limit,
    page: currPage,
    search,
    changeLimit: setLimit,
    changeSearch: setSearch,
    changePage: setCurrPage,
  } = useTableState({
    defaultLimit: 10,
    defaultFilters: {},
  });

  const { data: displayVendors, total: paginationTotalVendors } = useMemo(() => {
    return filterSortPaginate(vendors, {
      search,
      searchFields: SEARCH_FIELDS,
      limit,
      page: currPage,
      filters: [executeFilter],
      sortConfig,
    });
  }, [currPage, executeFilter, limit, search, sortConfig, vendors]);

  const handleSearchChange = (e: CustomEvent<string>) => setSearch(e.detail);

  const setFirstPage = () => {
    setCurrPage(
      new CustomEvent<{ page: number }>('pageChange', {
        detail: {
          page: 1,
        },
      }),
    );
  };

  const handleSortChange = (e: CustomEvent) => {
    const newSortBy = e.detail.sortId;
    const newSortDir = newSortBy !== sortConfig.field ? 'desc' : e.detail.direction;
    setFirstPage();
    setSortConfig({ dir: newSortDir, field: newSortBy ?? sortConfig.field });
  };

  const handleFilterChange = (newFilterChange: DidomiFiltersBarCustomEvent<FilterChangeType>) => {
    const key = newFilterChange.detail.key;
    const value = newFilterChange.detail.newValue;
    setFilter(currentFilter => ({ ...currentFilter, [key]: value }));
    setFirstPage();
  };

  const handleFiltersReset = () => {
    setSearch('');
    setFilter({});
    setSortConfig(INITIAL_SORT);
    setFirstPage();
  };

  const handleClose = () => {
    setVendorPreviewId(null);
  };

  const handleVendorChange = (id: string) => {
    setVendorPreviewId(id);
  };

  const onVendorClick = (vendorId: string) => {
    setVendorPreviewId(vendorId);
  };

  const displayPaginator = displayVendors.length > 0;

  return (
    <div>
      {!hideTableFilter && (
        <div style={{ width: 'calc(100% - 4px)', margin: 'auto' }}>
          <DidomiFiltersBar
            filters={generatedVendorsFilters}
            data-testid="filter-bar-vendor"
            id="didomi-filters-without-default-value-vendor"
            defaultValue=""
            left-text={hideTableLabel ? '' : `${paginationTotalVendors} vendors and domains`}
            showSearch
            numberOfVisibleFilters={4}
            onSearchTextChange={handleSearchChange}
            placeholderTextFilter={'Search by name'}
            onFilterValueChange={handleFilterChange}
            onClearAllFilters={handleFiltersReset}
          ></DidomiFiltersBar>
        </div>
      )}

      <DidomiTable sortable sortBy={sortConfig.field} sortDirection={sortConfig.dir} onSortChange={handleSortChange} style={{ width: 'calc(100% - 2px)', margin: '16px auto' }}>
        <DidomiTableHeading>
          <DidomiTableHeaderRow>
            <DidomiTableTh sortId="label" data-testid="sortByLabel">
              Name
            </DidomiTableTh>
            {!displayOnlyName && (
              <>
                <DidomiTableTh sortId="tcf_id" data-testid="sortByTCFId">
                  TCF ID
                </DidomiTableTh>
                <DidomiTableTh sortId="legal_basis" data-testid="sortByLegalBasis">
                  Legal Basis
                </DidomiTableTh>
                <DidomiTableTh sortId="request_count" data-testid="sortByRequests">
                  Requests for tags
                </DidomiTableTh>
                <DidomiTableTh sortId="functional_scenario" data-testid="sortByFunctionalScenario">
                  User behaviour
                </DidomiTableTh>
                <DidomiTableTh>Ran scenarios</DidomiTableTh>
              </>
            )}
          </DidomiTableHeaderRow>
        </DidomiTableHeading>
        <DidomiTableBody>
          {displayVendors.length > 0 && (
            <ComplianceReportDetailTableVendorRow vendors={displayVendors} onVendorClick={onVendorClick} displayOnlyName={displayOnlyName} isLoadingNodes={isLoading} />
          )}
          {!isLoading && displayVendors.length === 0 && <ComplianceReportDetailTableEmptyRow />}
        </DidomiTableBody>
      </DidomiTable>

      {displayPaginator && (
        <DidomiPaginator
          className="self-end mt-xs w-full justify-end flex"
          variant="normal"
          page={currPage}
          itemCount={paginationTotalVendors}
          size={limit}
          onPageSizeChange={setLimit}
          onPageChange={setCurrPage}
        />
      )}
      <InitiatorDetailModal
        isOpen={vendorPreviewId !== null}
        displayOnlyName={displayOnlyName}
        report={report}
        vendorId={vendorPreviewId}
        onVendorChange={handleVendorChange}
        onClose={handleClose}
      />
    </div>
  );
};

export { ComplianceReportCoreVendorTable };
