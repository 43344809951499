import { getTranslatedValue } from '@didomi/helpers';
import { VendorLinkType, VendorLink, Partner, Purpose } from '@types';

const getPurposeText = (dPID: string, purposes: Purpose[]) => getTranslatedValue(purposes?.find(p => p.id === dPID)?.description);

const getReadableLink = (links: VendorLink) =>
  links
    ? Object.keys(links)
        .map(typesOfLinks => ({
          label: VendorLinkType[typesOfLinks],
          value: links[typesOfLinks],
        }))
        .filter(l => !!l.value)
    : [];

export const mapVendorResult = (vendor: Partner, purposes: Purpose[] = []) => ({
  ...vendor,
  default_purposes_name: vendor.default_purposes_id?.map(pId => getPurposeText(pId, purposes)).filter(Boolean),
  legitimate_interest_purposes_name: vendor.legitimate_interest_purposes_id?.map(pId => getPurposeText(pId, purposes)).filter(Boolean),
  links_readable: getReadableLink(vendor?.links),
});
