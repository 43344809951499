import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DidomiEmptyState,
  DidomiFiltersBar,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableRow,
  DidomiTableTd,
  DidomiTableTh,
} from '@didomi/ui-atoms-react';
import { parse } from 'psl';
import { PropertyWithOptions } from '@enums';
import { useCollectedVendorsFromCr, usePaginatedFilteredItems, useTableState } from '@hooks';
import { CollectedVendors, SortConfig } from '@types';
import { getLastAnalyzedReport } from '@utils';
import { useProperty } from '../../hooks/data/properties/useProperty.hook';

type sortableVendorDomainsFields = 'name';
interface ComplianceReportUnmatchedVendorDomainsProps {
  selectedVendorHosts?: any[];
  setSelectedVendorHosts?: Function;
}

const TRANSLATABLE_SEARCH_FIELDS = [];
const SEARCH_FIELDS = [{ field: 'url_host' }, { field: 'url' }];
const vendorDomainsTableHeader = [{ name: 'Vendor domain', sortId: 'url_host' }];

const ComplianceReportUnmatchedVendorDomains = ({ selectedVendorHosts, setSelectedVendorHosts }: ComplianceReportUnmatchedVendorDomainsProps) => {
  const { propertyId } = useParams();
  const { isLoading: isLoadingProperty, data: property } = useProperty({ id: propertyId, with: [PropertyWithOptions.LATEST_REPORTS] });

  const [reportId, setReportId] = useState(property?.latest_reports[0]?.id);
  useEffect(() => {
    if (property?.latest_reports) {
      const lastReport = getLastAnalyzedReport(property);
      if (lastReport) setReportId(lastReport.id);
    }
  }, [property]);

  const { data: vendors, isLoading: isLoadingVendors } = useCollectedVendorsFromCr({ ids: reportId ? [reportId] : null, unmatchedDomainsOnly: true });

  const isLoading = isLoadingProperty || isLoadingVendors;

  const vendorHosts = useMemo(() => {
    const list = new Map();
    (vendors || [])
      .filter(vendor => !vendor?.id)
      .forEach(vendor => {
        const domain = vendor.url_sld || parse(vendor.url_host).domain;
        if (domain && !list.has(domain)) {
          list.set(domain, vendor);
        }
      });

    // return sorted array of objects alphabetically based on url_sld
    return Array.from(list, ([domain, value]) => ({ ...value, url_sld: domain })).sort((a, b) => a.url_sld.localeCompare(b.url_sld));
  }, [vendors]);
  const [sortConfig] = useState<SortConfig<sortableVendorDomainsFields>>({ field: 'name', dir: 'asc' });

  const {
    limit,
    page: currPage,
    search,
    changeLimit: setLimit,
    changeSearch: setSearch,
    changePage: setCurrPage,
    changeSorting,
  } = useTableState({
    defaultLimit: 10,
    defaultFilters: {},
  });
  const {
    data: displayVendorDomains,
    total: totalFilteredVendorsDomainCount,
    totalItems: totalFilteredVendorDomainsItems,
  } = usePaginatedFilteredItems<CollectedVendors, sortableVendorDomainsFields>(
    vendorHosts,
    {
      search,
      searchFields: SEARCH_FIELDS,
      translatableSearchFields: TRANSLATABLE_SEARCH_FIELDS,
      limit: limit,
      page: currPage,
      sortConfig,
    },
    'unmatch-vendors',
  );
  const handleSearchChange = (e: CustomEvent<string>) => setSearch(e.detail);
  const [selectedVendorDomainsRef, setSelectedVendorDomainsRef] = useState([]);

  useEffect(() => {
    if (selectedVendorHosts) setSelectedVendorDomainsRef(selectedVendorHosts);
  }, [selectedVendorHosts]);

  const handleSelection = useCallback(
    evt => {
      /* istanbul ignore if - Header checbox does not appear during jest test */
      if (evt.type === 'toggleAllRowsSelectedChange') {
        if (evt.detail.selectedItems.length) {
          setSelectedVendorDomainsRef(totalFilteredVendorDomainsItems);
          setSelectedVendorHosts(totalFilteredVendorDomainsItems);
        } else {
          setSelectedVendorDomainsRef([]);
          setSelectedVendorHosts([]);
        }
      } else {
        setSelectedVendorDomainsRef(evt.detail.newSelectedItems);
        setSelectedVendorHosts(evt.detail.newSelectedItems);
      }
    },
    [totalFilteredVendorDomainsItems, setSelectedVendorHosts],
  );

  return (
    <div className="mb-xxxl w-full h-auto">
      <DidomiSkeleton isLoading={isLoading} variant="layout">
        <>
          <section className="flex justify-between gap-8 mb-4 w-full" data-skeleton>
            <div className="flex gap-2 text-body-small self-center" style={{ alignSelf: 'center!important' }}>
              <div className="text-primary-blue-6 font-semibold">{`${displayVendorDomains.length} / ${vendorHosts.length} vendor domains`}</div>
              <div className="text-secondary-cobalt-blue-3">{selectedVendorDomainsRef.length ? selectedVendorDomainsRef.length + ' selected' : ''}</div>
            </div>

            <DidomiFiltersBar
              data-skeleton
              data-testid="filter-bar-vendor-domains"
              id="didomi-filters-without-default-value-vendor-domains"
              defaultValue=""
              filters={{}}
              showSearch={true}
              onSearchTextChange={handleSearchChange}
              placeholderTextFilter={'Search by name'}
              onClearAllFilters={setSearch}
              collapsibleFilters={false}
            ></DidomiFiltersBar>
          </section>
          <DidomiTable
            selectable
            selectedItems={selectedVendorDomainsRef}
            onRowSelectionChange={handleSelection}
            onToggleAllRowsSelectedChange={handleSelection}
            sortBy={sortConfig.field}
            sortDirection={sortConfig.dir}
            onSortChange={changeSorting}
            sortable={true}
            minWidth={300}
            data-skeleton
            className="w-[calc(100%-2px)] m-auto mt-xs mb-xs"
          >
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                {vendorDomainsTableHeader.map(obj => (
                  <DidomiTableTh key={obj.name} sortId={obj.sortId} className="text-uppercase">
                    {obj.name}
                  </DidomiTableTh>
                ))}
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody>
              {vendorHosts.length ? (
                displayVendorDomains.map((vendorDomain, i) => (
                  <DidomiTableRow key={vendorDomain.url_host} selectionValue={vendorDomain} data-testid={i}>
                    <DidomiTableTd>{vendorDomain.url_sld}</DidomiTableTd>
                  </DidomiTableRow>
                ))
              ) : (
                <DidomiEmptyState illustration-name="traces-no-match-found" className="border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1 mb-s">
                  <div slot="title">
                    There are no more unmatched vendors. <br /> Please run a new report to update the results with your new matched vendors.
                  </div>
                </DidomiEmptyState>
              )}
            </DidomiTableBody>
          </DidomiTable>
          <DidomiPaginator
            data-skeleton
            data-testid="domains-paginator"
            className="flex justify-end mt-s"
            page={currPage}
            itemCount={totalFilteredVendorsDomainCount}
            size={limit}
            onPageSizeChange={setLimit}
            onPageChange={setCurrPage}
          />
        </>
      </DidomiSkeleton>
    </div>
  );
};

export { ComplianceReportUnmatchedVendorDomains };
