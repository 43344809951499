import { AxiosError } from 'axios';
import { useQueryClient, UseQueryOptions } from 'react-query';
import { ReportOutputKey } from '@enums';
import { usePropertyReport } from '@hooks';
import { AggregatedTracker } from '@types';
import { AggregatedVendor } from 'interfaces';
import { useS3Output } from './useS3Output.hook';

const REPORT_STALE_TIME = 4 * 60 * 1000; // 4 minutes

type ReportOutputMap = {
  [ReportOutputKey.AGGREGATED_VENDORS]: AggregatedVendor[];
  [ReportOutputKey.AGGREGATED_TRACKERS]: AggregatedTracker[];
  [ReportOutputKey.AGGREGATED_VENDORS_GRAPH_EDGES]: AggregatedVendor[];
  [ReportOutputKey.AGGREGATED_VENDORS_GRAPH_NODES]: AggregatedVendor[];
};

type UseReportOutputOptions = UseQueryOptions<ReportOutputMap[ReportOutputKey], AxiosError> & {};

export const useReportOutput = <T>(
  reportId: string,
  key: ReportOutputKey,
  options: UseReportOutputOptions = {
    enabled: true,
  },
): {
  data: T[];
  isLoading: boolean;
  fetch: () => Promise<T[]>;
} => {
  const queryClient = useQueryClient();
  const cachedResult: T[] | undefined = queryClient.getQueryData(['report-output', reportId, key]);

  const {
    data: report,
    isLoading: isReportLoading,
    fetch: fetchReport,
  } = usePropertyReport({
    reportId,
    enabled: !cachedResult && options.enabled,
    staleTime: REPORT_STALE_TIME,
    isLegacy: false,
  });

  const {
    data: result,
    isLoading: isS3OutputLoading,
    fetch: fetchS3Output,
  } = useS3Output(reportId, key, report?.outputs?.[key], {
    ...options,
    enabled: !!report?.outputs?.[key] && options.enabled,
  });

  const fetch = async <T>() => {
    // Return cached result if it exists
    if (cachedResult) {
      return cachedResult;
    }

    // Fetch report if stale time is expired
    const report = await fetchReport(reportId, { staleTime: REPORT_STALE_TIME });

    // Fetch S3 output
    const output = await fetchS3Output(report.outputs[key]);

    return output as T[];
  };

  return {
    data: cachedResult || (result as T[]),
    isLoading: isReportLoading || isS3OutputLoading,
    fetch,
  };
};
