import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  DidomiButton,
  DidomiCardContainer,
  DidomiCheckbox,
  DidomiCheckboxField,
  DidomiCollapsibleSection,
  DidomiHintbox,
  DidomiIconButton,
  DidomiLink,
  DidomiNumberInputField,
  DidomiPasswordInputField,
  DidomiSelectField,
  DidomiSelectOption,
  DidomiSelectOptions,
  DidomiSkeleton,
  DidomiTextInput,
  DidomiTextInputField,
} from '@didomi/ui-atoms-react';
import { useFeatureFlag, useSPARouter } from '@didomi/utility-react';
import { Form, Formik, FormikProps } from 'formik';

import { ScenariosSection, TruncatedTextWithTooltip } from '@components';
import { NO_SCHEDULE_SET, SCHEDULE_LIST_MAP, NOT_NULL_DEFAULT_PASSWORD, DEFAULT_PAGES_COUNT } from '@constants';
import { useScenarioChanges } from '@hooks';
import { LoadingModal } from '@modals';
import { COUNTRY_LIST, omitScenariosGeneratedFields, PROPERTY_SCHEMA, PropertySchema, validateCustomPage } from '@utils';
import { Property } from '../../types/responses/property';

export type InitialValues = Partial<Property> & {
  isOwner: boolean;
  filterValue: string;
  isFilterRegex: boolean;
};

interface PropertyFormProps {
  initialValues: InitialValues;
  onSubmit: (property: Partial<Property>) => Promise<void>;
  isLoading?: boolean;
  isSaving?: boolean;
  maxPages?: number;
}

const sanitizeLogin = (login: Property['login'] | undefined) => {
  return login?.enabled
    ? JSON.parse(JSON.stringify(login, (key, value) => (value === '' ? undefined : value)))
    : {
        enabled: false,
      };
};

export const PropertyForm = ({ initialValues, onSubmit, isLoading = false, isSaving = false, maxPages = 20 }: PropertyFormProps) => {
  const propertyFormRef = useRef<FormikProps<InitialValues>>();

  const [hasComplianceReportPremiumFeature] = useFeatureFlag('compliance_report_v2');

  const [customPageInputValue, setCustomPageInputValue] = useState<string>('');
  const [customPageUrls, setCustomPageUrls] = useState<string[]>([]);
  const [customPageInputError, setCustomPageInputError] = useState<string | null>(null);

  const validatePagesCount = useCallback(
    (values: InitialValues) => {
      const errors: any = {};
      const pagesCount = parseInt(values.pages_count.toString(), 10);

      if (isNaN(pagesCount)) {
        errors.pages_count = 'Please enter a valid number';
      } else if (pagesCount <= 0 || pagesCount > maxPages) {
        errors.pages_count = `The number of pages must be between 1 and ${maxPages}`;
      }

      return errors;
    },
    [maxPages],
  );

  const {
    handleEnabledChange,
    handleAddScenario,
    handleDeleteScenario,
    handleNameChange,
    handleAddAction,
    handleDeleteAction,
    handleMoveAction,
    handleCssSelectorChange,
    handleInputValueChange,
  } = useScenarioChanges(propertyFormRef);

  const { navigateTo } = useSPARouter();

  /**
   * Set the custom page urls from the initial values
   */
  useEffect(() => {
    if (initialValues.custom_pages) {
      setCustomPageUrls(initialValues.custom_pages);
    }
  }, [initialValues]);

  const handleSubmit = useCallback(
    async data => {
      // format the login object
      // to not send empty fields to the API

      // We generate a random id for each action, to give a unique key in the React list.
      // This key allows the action to be rerendered on change (add, update, delete, reorder, etc..).
      // We don't want to send this id to the API, so we remove it before sending the data.
      // Additionally, on edit, when we receive the scenarios and actions,
      // they are populated with id, created_at, and modified_at.
      // We need to remove these fields as well because the DTOs do not expect them.
      const scenariosWithoutActionIDs = omitScenariosGeneratedFields(data.scenarios);
      const filters = [];
      if (data.filterValue.length) {
        filters.push({
          type: data.isFilterRegex ? 'regexp' : 'string',
          value: data.filterValue,
        });
      }

      const property: PropertySchema = PROPERTY_SCHEMA.cast({
        name: data.name,
        website: data.website,
        scenarios: scenariosWithoutActionIDs,
        pages_count: data?.pages_count || DEFAULT_PAGES_COUNT,
        schedule: data.schedule === NO_SCHEDULE_SET ? null : data.schedule || '0 0 1 * *',
        user_agent: data.user_agent,
        country: data.country,
        login: data?.login,
        filters,
        ...(customPageUrls.length > 0 ? { custom_pages: customPageUrls } : {}),
      });
      property.login = sanitizeLogin(property.login);

      await onSubmit(property);
    },
    [onSubmit, customPageUrls],
  );

  const handlePasswordFocusIn = () => {
    // if username is non-null, clear the password field on focus
    if (initialValues.id && propertyFormRef.current.values?.login?.enabled) {
      propertyFormRef.current.setFieldValue('login.credentials.password', '', false);
    }
  };

  const handlePasswordFocusOut = () => {
    // if login is enabled and the password is not updated, set the password field to the default value on focus out
    if (initialValues.id && initialValues.login?.enabled && !propertyFormRef.current.values.login?.credentials?.password) {
      propertyFormRef.current.setFieldValue('login.credentials.password', NOT_NULL_DEFAULT_PASSWORD, false);
    }
  };

  /**
   * Add a new custom page to the list
   * @param url - The URL to add
   */
  const handleAddNewCustomPage = (url: string) => {
    const { values } = propertyFormRef.current;
    const { isValid, errorMessage } = validateCustomPage(url, values.website, customPageUrls);
    if (!isValid) {
      setCustomPageInputError(errorMessage);
      return;
    }

    // add the url to the list
    setCustomPageUrls([...customPageUrls, url.trim()]);

    // clear the error message
    setCustomPageInputError(null);

    // clear the input field
    setCustomPageInputValue('');
  };

  const handleInputChange = (event: CustomEvent<string>) => {
    setCustomPageInputValue(event.detail);

    // validate url only if the url are submitted
    if (!customPageInputError) {
      return;
    }

    const { values } = propertyFormRef.current;
    const { isValid, errorMessage } = validateCustomPage(event.detail, values.website, customPageUrls);
    if (!isValid) {
      setCustomPageInputError(errorMessage);
      return;
    } else {
      setCustomPageInputError(null);
    }
  };

  const handleDeleteCustomPage = (index: number) => {
    setCustomPageUrls(customPageUrls.filter((_, i) => i !== index));
  };

  return (
    <section className="mt-m">
      <LoadingModal isOpen={isSaving} isEditing={!!initialValues.id} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={PROPERTY_SCHEMA}
        onSubmit={handleSubmit}
        validateOnMount={true}
        innerRef={propertyFormRef}
        validate={validatePagesCount}
      >
        {({ values, submitForm, setFieldValue }) => (
          <DidomiSkeleton className="w-full" isLoading={isLoading} variant="layout">
            <Form className="w-full max-w-[700px] flex flex-col mb-s">
              <DidomiTextInputField name="name" label="Give this domain a recognisable name *" placeholder="Enter a name..." data-skeleton />
              <DidomiTextInputField name="website" label="Target domain *" placeholder="https://www.myDomain.com" data-skeleton />
              <DidomiSelectField className="max-w-[316px]" name="country" placeholder="" label="Launched from" required={true} data-skeleton>
                <DidomiSelectOptions>
                  {COUNTRY_LIST.map(country => (
                    <DidomiSelectOption key={'country' + country.value} value={country.value} label={country.name} disabled={country.value === values.country} />
                  ))}
                </DidomiSelectOptions>
              </DidomiSelectField>

              <ScenariosSection
                scenarios={propertyFormRef.current?.values.scenarios}
                hasComplianceReportPremiumFeature={hasComplianceReportPremiumFeature}
                onEnabledChange={handleEnabledChange}
                onAddScenario={handleAddScenario}
                onDeleteScenario={handleDeleteScenario}
                onNameChange={handleNameChange}
                onAddAction={handleAddAction}
                onDeleteAction={handleDeleteAction}
                onMoveAction={handleMoveAction}
                onCssSelectorChange={handleCssSelectorChange}
                onInputValueChange={handleInputValueChange}
              />

              <DidomiHintbox titleText="Advanced Settings" variant="neutral" className="mt-xxs mb-s">
                <p>
                  Read our <DidomiLink href="https://support.didomi.io/run-your-audit-add-your-website" text="Help Center"></DidomiLink> for a deep explanation of the advanced
                  settings.
                </p>
              </DidomiHintbox>

              <DidomiCollapsibleSection label="Advanced" data-testid="collapse-settings" className="mb-xs">
                <div>
                  {hasComplianceReportPremiumFeature && (
                    <>
                      <DidomiSelectField
                        className="max-w-[316px]"
                        data-testid="scan-frequency"
                        name="schedule"
                        placeholder="Monthly"
                        label="Frequency of the programmed report"
                        data-skeleton
                      >
                        <DidomiSelectOptions>
                          {Object.entries(SCHEDULE_LIST_MAP).map(([label, value]) => (
                            <DidomiSelectOption key={'schedule' + value} value={value} label={label} />
                          ))}
                        </DidomiSelectOptions>
                      </DidomiSelectField>

                      {values?.schedule === SCHEDULE_LIST_MAP['Daily'] && (
                        <DidomiHintbox iconName="warning" variant="warning" className="mb-xs" data-testid="daily-scan-warning">
                          <p>
                            <span>Enabling daily scans may result in changes to your Didomi payment plan.</span>
                            <br />
                            <span>
                              Please <b>contact Customer Support</b> for more information.
                            </span>
                          </p>
                        </DidomiHintbox>
                      )}
                    </>
                  )}

                  <DidomiTextInputField name="filterValue" label="URL filter, to target specific pages" placeholder="eg. /products/ " data-skeleton />
                  <DidomiCheckbox
                    label="The URL filter is a RegEx"
                    name="isFilterRegex"
                    checked={values.isFilterRegex}
                    onValueChange={event => setFieldValue('isFilterRegex', event.detail)}
                    data-skeleton
                    data-testid="is-filter-regex"
                  />
                  <DidomiNumberInputField name="pages_count" label={`N. of pages (${maxPages} max)`} data-skeleton data-testid="pages-count-input-container" />

                  {hasComplianceReportPremiumFeature && (
                    <>
                      {values.pages_count > 0 && values.pages_count < customPageUrls.length && (
                        <DidomiHintbox titleText={`You have specified ${customPageUrls.length} urls`} variant="warning" className="mt-xxs mb-s">
                          <p>{customPageUrls.length - values.pages_count} of them will be disabled if you decrease the quantity here.</p>
                        </DidomiHintbox>
                      )}

                      <DidomiHintbox titleText="You can specify all the pages you want to scan" variant="neutral" className="mt-xxs mb-s">
                        <p>Enter the URLs of the pages to be scanned. If you don&apos;t reach all the pages, the remaining pages will be randomly selected from your site map.</p>
                      </DidomiHintbox>

                      <div className="relative">
                        <DidomiTextInput
                          name="custom_page_url"
                          label="Specify pages to scan"
                          description={values.pages_count > 0 ? `${values.pages_count} urls max` : ''}
                          placeholder="Enter URL"
                          error={customPageInputError}
                          className="w-full [&_input]:!mr-[45px]"
                          data-skeleton
                          data-testid="custom-page-url-input"
                          value={customPageInputValue}
                          onValueChange={handleInputChange}
                          onKeyDown={event => {
                            if (customPageInputValue && event.key === 'Enter') {
                              handleAddNewCustomPage(customPageInputValue);
                            }
                          }}
                        />

                        <div className="absolute top-1/2 right-xs -translate-y-1/2">
                          <DidomiIconButton
                            size="small"
                            icon="new-create"
                            className="mb-xxxs"
                            disabled={!customPageInputValue}
                            onClick={() => handleAddNewCustomPage(customPageInputValue)}
                            data-testid="add-custom-page-button"
                          ></DidomiIconButton>
                        </div>
                      </div>

                      {values.pages_count > 0 && values.pages_count < customPageUrls.length && (
                        <DidomiHintbox titleText={`You need to delete ${customPageUrls.length - values.pages_count} URL/s`} variant="warning" className="mt-xxs mb-s">
                          <p>You have specified more pages to scan than the max number of pages to scan.</p>
                          <p>Specified pages exceeding the max number will be ignored during the scan.</p>
                        </DidomiHintbox>
                      )}
                    </>
                  )}

                  {customPageUrls.length > 0 && (
                    <div className="flex flex-col gap-xxs mb-xs">
                      {customPageUrls.map((url, index) => (
                        <DidomiCardContainer key={index} style={{ '--card-inner-padding': '6px 10px', '--card-radius': '8px' }}>
                          <div className="flex items-center justify-between">
                            <div className="w-[calc(100%-35px)]">
                              <TruncatedTextWithTooltip className="text-[14px] text-primary-blue-6" value={url} />
                            </div>
                            <DidomiIconButton
                              icon="close-sm"
                              size="small"
                              variant="rounded-no-border"
                              className="text-primary-blue-6"
                              onClick={() => handleDeleteCustomPage(index)}
                              data-testid={`delete-custom-page-button-${index}`}
                            ></DidomiIconButton>
                          </div>
                        </DidomiCardContainer>
                      ))}
                    </div>
                  )}

                  {hasComplianceReportPremiumFeature && (
                    <DidomiTextInputField
                      name="user_agent"
                      label="User agent"
                      placeholder="Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36 Didomi/1.0"
                      data-skeleton
                    />
                  )}
                </div>
              </DidomiCollapsibleSection>

              {hasComplianceReportPremiumFeature && (
                <DidomiCollapsibleSection label="Scan logged in environment" isCollapsed={!values.login.enabled} className="mb-xs">
                  <div>
                    <p className="text-[12px] text-primary-blue-6 font-semibold mb-xxs">Logged environment configuration</p>

                    <DidomiCheckboxField
                      label="Scan logged environment"
                      checked={values.login.enabled}
                      onValueChange={(event: CustomEvent<boolean>) => setFieldValue('login.enabled', event.detail)}
                      data-skeleton
                      data-testid="auth-enabled-checkbox"
                      name="login.enabled"
                    />

                    {values.login.enabled && (
                      <>
                        <DidomiHintbox titleText="Scan logged in environment" variant="neutral" className="mt-xxs mb-xs">
                          <p>In order to let our bot scan your website while logged-in, we need you to provide the necessary credentials.</p>
                        </DidomiHintbox>

                        <DidomiTextInputField name="login.selectors.pathToForm" label="Login page URL" placeholder="e.g. https://example.com/login/" required data-skeleton />
                        <DidomiTextInputField name="login.credentials.username" label="Username" placeholder="e.g. admin" required autocomplete="off" data-skeleton />
                        <DidomiPasswordInputField
                          name="login.credentials.password"
                          label="Password"
                          placeholder="e.g. password"
                          required
                          autocomplete={'new-password' as any}
                          hideShowPasswordButton={true}
                          onFocusin={handlePasswordFocusIn}
                          onFocusout={handlePasswordFocusOut}
                          data-skeleton
                        />
                        <DidomiTextInputField name="login.selectors.usernameField" label="Username CSS selector" placeholder="e.g. #username-input" data-skeleton />
                        <DidomiTextInputField name="login.selectors.passwordField" label="Password CSS selector" placeholder="e.g. #password-input" data-skeleton />
                        <DidomiTextInputField name="login.selectors.submitButton" label="Submit button CSS selector" placeholder="e.g. #submit-btn" data-skeleton />
                      </>
                    )}
                  </div>
                </DidomiCollapsibleSection>
              )}

              <DidomiHintbox titleText="Confirm you have the right to add this domain on the Didomi platform" variant="neutral" className="mt-xxs mb-xs">
                <p>Our bot will keep it monitored and will perform several test on it, you need the owner authorisation.</p>
              </DidomiHintbox>
              <DidomiCheckboxField
                name="isOwner"
                data-testid="owner-checkbox"
                label="I am the owner of this domain or acting on behalf of its owner (mandatory) *"
                checked={values.isOwner}
                onValueChange={(event: CustomEvent<boolean>) => setFieldValue('isOwner', event.detail)}
                required
                data-skeleton
              ></DidomiCheckboxField>
              <div className="w-full flex justify-end mt-5 mb-10">
                <DidomiButton
                  type="button"
                  variant="secondary"
                  className="mr-xs"
                  data-testid="cancel-button"
                  onClick={() => navigateTo('agnostik-compliance-report/')}
                  data-skeleton
                >
                  Cancel
                </DidomiButton>
                <DidomiButton type="button" iconRight="save" data-testid="save-button" onClick={submitForm} data-skeleton>
                  Save
                </DidomiButton>
              </div>
            </Form>
          </DidomiSkeleton>
        )}
      </Formik>
    </section>
  );
};
