import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, OrganizationScrapingQuota } from '@types';

type useOrganizationScrapingQuotaArgs = {
  enabled?: boolean;
};

/**
 * Hooks to read organization scrapping quotas from the server
 */
export const useOrganizationScrapingQuotas = ({ enabled = true }: useOrganizationScrapingQuotaArgs = {}) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchOrganizationScrapingQuotas = async () => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<OrganizationScrapingQuota>>(`${CONFIG.environmentConfig.apiBaseUrl}quotas`, {
        params: {
          organization_id: organizationId,
        },
      });
      return response.data.data[0];
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return useQuery<OrganizationScrapingQuota, AxiosError>([`organization-scraping-quota`, organizationId], () => fetchOrganizationScrapingQuotas(), {
    refetchInterval: false,
    enabled,
    staleTime: 0,
    cacheTime: 30_000,
  });
};
