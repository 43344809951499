import { Link } from 'react-router-dom';
import React from 'react';
import { DidomiIconButton } from '@didomi/ui-atoms-react';
import { settingsRoute } from '@constants';

export const SettingsActionButton = () => {
  return (
    <div>
      <Link to={settingsRoute} data-testid="settings-link">
        <DidomiIconButton variant="rounded-border" icon="settings"></DidomiIconButton>
      </Link>
    </div>
  );
};
