import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse } from '@types';
import { NoticeTemplate } from '../../../interfaces/notice-template.interface';

export const useNoticeTemplates = (options?: UseQueryOptions<PaginatedResponse<NoticeTemplate>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<NoticeTemplate>>(apiRoutes.noticeTemplates, {
      params: { organization_id: organizationId },
    });
    return response.data;
  };

  return useQuery<PaginatedResponse<NoticeTemplate>, AxiosError>(['notice-templates', { organization_id: organizationId }], fetch, options);
};
