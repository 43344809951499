import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosResponse, AxiosError } from 'axios';
import { useMutation, useQueryClient, UseMutationOptions } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { Property } from '../../../types/responses/property';

interface Options {
  property_id: string;
}
export const useRemoveProperty = (options: UseMutationOptions<AxiosResponse<Property>, AxiosError, Partial<Options>>) => {
  const queryClient = useQueryClient();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  return useMutation(
    async (option: Partial<Options>) => {
      return axiosWithInterceptors.delete<Property>(`${apiRoutes.crProperties}/${option.property_id}`, {
        params: {
          organization_id: organizationId,
        },
      });
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['properties', organizationId] });
      },
    },
  );
};
