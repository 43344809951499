import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { OrganizationProperty } from '@types';

/**
 * Updates a list of domains
 */
export const useEditDomains = (options?: UseMutationOptions<AxiosResponse<OrganizationProperty[]>, AxiosError, unknown>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: [OrganizationProperty[]]) => {
      return axiosWithInterceptors.patch<Partial<OrganizationProperty[]>>(`${apiRoutes.crProperties}/?organization_id=${organizationId}`, payload);
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.refetchQueries('properties');
        queryClient.invalidateQueries('property');

        options?.onSuccess(...args);
      },
    },
  );
};
