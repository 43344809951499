export const COUNTRY_LIST = [
  { name: 'Belgium', value: 'be' },
  { name: 'Canada', value: 'ca' },
  { name: 'France', value: 'fr' },
  { name: 'Germany', value: 'de' },
  { name: 'Great Britain', value: 'gb' },
  { name: 'Spain', value: 'es' },
  { name: 'United States', value: 'us' },
  { name: 'Brazil', value: 'br' },
];
