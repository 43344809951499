import { useCallback, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { PageLimitType } from '@types';
import { useQueryParams, UseUrlQueryParamOptions } from './useQueryParams.hook';

const PAGE_KEY = 'page';
const LIMIT_KEY = 'limit';
const SEARCH_QUERY_KEY = 'query';
const DEFAULT_VALUE_PAGE = 1;
const DEFAULT_VALUE_LIMIT = 5 as PageLimitType;
const SEARCH_DEBOUNCE_TIME = 500;

interface PaginationOptions extends UseUrlQueryParamOptions {
  defaultLimit?: PageLimitType;
  pushHistory?: boolean;
  debounceSearch?: boolean;
  searchQueryKey?: string;
}

const isNotOrNegativeNumber = (value: number) => Number.isNaN(value) || !value || value < 0;

export const usePaginationQueryParams = (options?: PaginationOptions) => {
  const defaultLimit = options?.defaultLimit ?? DEFAULT_VALUE_LIMIT;
  const searchQueryKey = options?.searchQueryKey ?? SEARCH_QUERY_KEY;

  const { getQueryParam, setQueryParams, clearQueryParams } = useQueryParams({
    pushHistory: options?.pushHistory,
    defaultValues: {
      [`${PAGE_KEY}`]: DEFAULT_VALUE_PAGE,
      [`${LIMIT_KEY}`]: defaultLimit,
      [`${searchQueryKey}`]: '',
    },
  });

  const paramPageValue = getQueryParam(PAGE_KEY);
  const paramLimitValue = getQueryParam(LIMIT_KEY);
  const search = (getQueryParam(searchQueryKey) as string) || '';

  const page = useMemo(() => {
    const pageNum = Number.parseInt(paramPageValue as string);
    if (isNotOrNegativeNumber(pageNum)) {
      return DEFAULT_VALUE_PAGE;
    }
    return pageNum;
  }, [paramPageValue]);

  const limit = useMemo(() => {
    const pageLimit = Number.parseInt(paramLimitValue as string);
    if (isNotOrNegativeNumber(pageLimit)) {
      return defaultLimit;
    }
    return pageLimit as PageLimitType;
  }, [paramLimitValue, defaultLimit]);

  const skip = useMemo(() => limit * (page - 1), [limit, page]);

  const setPage = useCallback(
    (ev: CustomEvent<{ page: number }>) => {
      setQueryParams({ [`${PAGE_KEY}`]: ev.detail.page });
    },
    [setQueryParams],
  );

  const setLimit = useCallback(
    (ev: CustomEvent<{ limit: number }>) => {
      setQueryParams({
        [`${PAGE_KEY}`]: 1,
        [`${LIMIT_KEY}`]: ev.detail.limit,
      });
    },
    [setQueryParams],
  );

  const setSearch = useCallback(
    (ev: CustomEvent<string>) => {
      clearQueryParams();
      setQueryParams({ [`${PAGE_KEY}`]: 1, [`${searchQueryKey}`]: ev.detail });
    },
    [setQueryParams, clearQueryParams, searchQueryKey],
  );

  const setDebouncedSearch = useMemo(() => {
    return debounce(setSearch, SEARCH_DEBOUNCE_TIME);
  }, [setSearch]);

  return {
    page,
    limit,
    skip,
    setPage,
    setLimit,
    search,
    setSearch: options?.debounceSearch ? setDebouncedSearch : setSearch,
  };
};
