import React, { useState, useMemo } from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiAutocompleteCustomEvent } from '@didomi/ui-atoms';
import { DidomiModal, DidomiModalHeader, DidomiModalContent, DidomiButton, DidomiSelectOptions, DidomiSelectOption, DidomiAutocomplete } from '@didomi/ui-atoms-react';
import { Purpose } from '@types';

interface AssignTrackerPurposesModalProps {
  isOpen: boolean;
  purposes: Purpose[];
  selectedPurposeIds: string[];
  onClose: (value: boolean) => void;
  onSubmit: (data: { selectedPurposeIds?: string[]; selectedExemptionCategoryId?: string }) => void;
  setSelectedPurposeIds: (ids: string[]) => void;
}

export const AssignTrackerPurposesModal = ({ isOpen, purposes, selectedPurposeIds, onClose, onSubmit, setSelectedPurposeIds }: AssignTrackerPurposesModalProps) => {
  const [searchQuery, setSearchQuery] = useState<string>();

  const handleDialogChange = (e: CustomEvent) => {
    if (isOpen && !e.detail) {
      onClose(e.detail);
    }
  };

  const onPurposeSelect = (e: DidomiAutocompleteCustomEvent<string[]>) => {
    setSelectedPurposeIds(e.detail);
  };

  const handleSearchChange = async (e: CustomEvent<string>) => {
    const searchQuery = e.detail.toLowerCase();
    if (!searchQuery) {
      setSearchQuery(null);
    }
    setSearchQuery(searchQuery);
  };

  const handleInputClear = () => {
    setSelectedPurposeIds([]);
  };

  const filteredPurposes = useMemo(() => {
    if (!searchQuery) return purposes;

    return purposes?.filter(purpose => getTranslatedValue(purpose.description).toLowerCase().includes(searchQuery));
  }, [searchQuery, purposes]);

  const isSubmitDisabled = selectedPurposeIds.length === 0;

  return (
    <div>
      <DidomiModal id="modal" variant="slim" isOpen={isOpen} onOpenChange={handleDialogChange} data-testid="ValidationPurposeAssignmentModal" closable>
        <DidomiModalHeader modal-sub-title="Assign purposes" modal-description="You can select one or several purposes"></DidomiModalHeader>
        <DidomiModalContent>
          {purposes && (
            <>
              <DidomiAutocomplete
                multi={true}
                data-testid="purposes-autocomplete"
                label="Purposes"
                placeholder={'Select purposes'}
                name="select-purposes"
                className="mt-4"
                data-skeleton
                value={selectedPurposeIds}
                onValueChange={onPurposeSelect}
                onSearchChange={handleSearchChange}
                onAutocompleteClear={handleInputClear}
                size="md"
              >
                <DidomiSelectOptions>
                  {filteredPurposes &&
                    filteredPurposes?.map((purpose: Purpose) => (
                      <DidomiSelectOption
                        label={getTranslatedValue(purpose.description)}
                        value={purpose.id}
                        key={purpose.id}
                        selectable={true}
                        selected={selectedPurposeIds.includes(purpose.id)}
                      />
                    ))}
                </DidomiSelectOptions>
              </DidomiAutocomplete>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                <DidomiButton size="medium" variant="secondary" style={{ marginBottom: '10px' }} full-width onClick={() => onClose(false)}>
                  Cancel
                </DidomiButton>
                <DidomiButton size="medium" variant="main" full-width disabled={isSubmitDisabled} onClick={() => !isSubmitDisabled && onSubmit({ selectedPurposeIds })}>
                  Save
                </DidomiButton>
              </div>
            </>
          )}
        </DidomiModalContent>
      </DidomiModal>
    </div>
  );
};
