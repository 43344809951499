import React, { useCallback, useMemo } from 'react';
import { useActiveOrganization, useFeatureFlag, useSPARouter, useSnackbar } from '@didomi/utility-react';
import { useQueryClient } from 'react-query';
import { PropertyForm, InitialValues } from '@components';
import { acmScenariosInitData, nonAcmScenariosInitData } from '@constants';
import { useCreateDomain } from '@hooks';
import { Property } from '../../types/responses/property';

const getInitialValues = (): InitialValues => ({
  name: '',
  website: '',
  isOwner: false,
  scenarios: nonAcmScenariosInitData,
  filterValue: '',
  isFilterRegex: false,
  pages_count: 10,
  schedule: '0 0 1 * *',
  login: {
    enabled: false,
    selectors: {
      pathToForm: '',
      usernameField: '',
      passwordField: '',
      submitButton: '',
    },
    credentials: {
      username: '',
      password: '',
    },
  },
  filters: [],
  country: 'fr',
});

export const AddDomain = () => {
  const { mutateAsync: createNewDomain, isLoading: isSaving } = useCreateDomain();
  const { displaySnackbar } = useSnackbar();
  const [hasComplianceReportPremiumFeature, isLoadingComplianceReportPremiumFeature] = useFeatureFlag('compliance_report_v2');
  const queryClient = useQueryClient();
  const { organizationId } = useActiveOrganization();
  const { navigateTo } = useSPARouter();

  const initialValues = useMemo(() => {
    if (!isLoadingComplianceReportPremiumFeature && hasComplianceReportPremiumFeature !== null) {
      const scenarios = hasComplianceReportPremiumFeature ? acmScenariosInitData : nonAcmScenariosInitData;
      return {
        ...getInitialValues(),
        scenarios,
      };
    }

    return getInitialValues();
  }, [hasComplianceReportPremiumFeature, isLoadingComplianceReportPremiumFeature]);

  const handleSubmit = useCallback(
    async (property: Partial<Property>) => {
      try {
        await createNewDomain(property);
        await queryClient.invalidateQueries({ queryKey: ['properties', organizationId] });
        await queryClient.invalidateQueries({ queryKey: ['property'] });
        await queryClient.invalidateQueries({ queryKey: ['report'] });
        await displaySnackbar('Domain have been saved!', { icon: 'check' });
        navigateTo('agnostik-compliance-report/');
      } catch (error) {
        await displaySnackbar('There was an error saving the domain', { variant: 'error' });
      }
    },
    [createNewDomain, queryClient, organizationId, displaySnackbar, navigateTo],
  );

  return <PropertyForm initialValues={initialValues} isSaving={isSaving} onSubmit={handleSubmit} />;
};
