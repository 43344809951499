/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DidomiCardContainer, DidomiSelect, DidomiSelectOptions, DidomiSelectOption, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { DataSet } from 'vis-data/peer/esm/vis-data';
import { Network } from 'vis-network';
import { TRANSLATED_SCENARIOS_MAP, VENDOR_GRAPH_OPTIONS, VENDOR_GRAPH_NODE_COLORS } from '@constants';
import { ReportOutputKey, ScenarioType } from '@enums';
import { useReportOutput } from '@hooks';
import { InitiatorDetailModal } from '@modals';
import { Report, VendorGraphNode, VendorGraphEdge } from '@types';

interface ComplianceReportVendorGraphProps {
  report: Report;
}

const ComplianceReportVendorGraph = ({ report }: ComplianceReportVendorGraphProps) => {
  const { data: vendorsGraphNodes } = useReportOutput<VendorGraphNode>(report?.id, ReportOutputKey.AGGREGATED_VENDORS_GRAPH_NODES);
  const { data: vendorsGraphEdges } = useReportOutput<VendorGraphEdge>(report?.id, ReportOutputKey.AGGREGATED_VENDORS_GRAPH_EDGES);
  const [isOpen, setIsOpen] = useState(false);
  const [clickedVendorNodeId, setClickedVendorNodeId] = useState(null);
  const [network, setNetwork] = useState(null);
  const [selectedScenario, setSelectedScenario] = useState<ScenarioType>(ScenarioType.CONSENT_TO_ALL);

  const graphContainerRef = useRef<HTMLDivElement | null>(null);

  /**
   * Trigger the vendor preview modal
   */
  const triggerModalBySelectingVendor = vendorId => {
    setClickedVendorNodeId(vendorId);
    setIsOpen(true);
  };

  /**
   * Handle the close of the vendor preview modal
   */
  const handleClose = () => {
    setIsOpen(false);
  };

  /**
   * Handle vendor change from the vendor preview modal
   */
  const handleVendorChange = (id: string) => {
    setClickedVendorNodeId(id);
    network.focus(id, { animation: true });
    network.selectNodes([id]);
  };

  /**
   * Get the available scenarios from the vendors graph nodes
   */
  const availableScenarios = useMemo(() => {
    const uniqueScenarios = new Set(vendorsGraphNodes?.map(node => node.cmp.scenario.id).flat());
    return Array.from(uniqueScenarios).map(scenario => ({ value: scenario, label: TRANSLATED_SCENARIOS_MAP[scenario] }));
  }, [vendorsGraphNodes]);

  /**
   * Enrich the vendors graph nodes with the shape and color
   */
  const getUpdatedVendorsGraphNodes = (vendorsGraphNodes: VendorGraphNode[]) => {
    return vendorsGraphNodes?.map(node => ({
      ...node,
      shape: node.initiated_trackers.length ? 'triangle' : 'square',
      color: VENDOR_GRAPH_NODE_COLORS[node.distance_from_property],
    }));
  };

  /**
   * Enrich the vendors graph edges with the color, dashes, width and arrows
   */
  const getUpdatedVendorsGraphEdges = (vendorsGraphEdges: VendorGraphEdge[]) => {
    return vendorsGraphEdges?.map(edge => ({
      ...edge,
      color: '#ccc',
      dashes: false,
      width: 0.02,
      arrows: {
        to: {
          enabled: true,
          scaleFactor: 0.5,
          type: 'arrow',
        },
      },
    }));
  };

  /**
   * Bind the click event on the vendor graph
   */
  const addClickEventHandler = (network: Network) => {
    let lastSelectedNodeId = null;
    network.on('click', ev => {
      if (!ev?.nodes?.length) {
        return;
      }

      // open the vendor preview modal when we click again on the highlighted node
      if (ev?.nodes?.[0] === lastSelectedNodeId) {
        triggerModalBySelectingVendor(ev?.nodes[0]);
        network.unselectAll();
        lastSelectedNodeId = null;
        return;
      }

      ev?.nodes?.[0] && network.focus(ev?.nodes[0]);
      lastSelectedNodeId = ev?.nodes?.[0];
      network.unselectAll();
      network.selectNodes([lastSelectedNodeId], true);
    });
  };

  useEffect(() => {
    // wait for data to be loaded
    if (!vendorsGraphNodes?.length || !vendorsGraphEdges?.length) return;

    const container = graphContainerRef.current;
    // wait for container to be rendered
    if (!container) return;

    // filter the vendors graph nodes by the selected scenario
    const filteredVendorsGraphNodes = vendorsGraphNodes.filter(node => node.cmp.scenario.id.includes(selectedScenario) || node.is_property);

    // enrich the vendors graph nodes and edges
    const updatedVendorsGraphNodes = getUpdatedVendorsGraphNodes(filteredVendorsGraphNodes);
    const updatedVendorsGraphEdges = getUpdatedVendorsGraphEdges(vendorsGraphEdges);

    // create the data sets
    const nodesDataSet = new DataSet(updatedVendorsGraphNodes);
    const edgesDataSet = new DataSet(updatedVendorsGraphEdges);

    // create the vendor graph
    const network = new Network(container, { nodes: nodesDataSet, edges: edgesDataSet }, VENDOR_GRAPH_OPTIONS);
    setNetwork(network);

    // stabilize the network
    network.stabilize(nodesDataSet.length * 2);

    const masterNode = filteredVendorsGraphNodes.find(node => node.is_property);
    if (masterNode) {
      network.once('stabilized', () => {
        network.focus(masterNode.id, { locked: false, animation: true });
      });
    }

    // handle the click event on the vendor graph
    addClickEventHandler(network);
  }, [vendorsGraphNodes, vendorsGraphEdges, graphContainerRef, selectedScenario]);

  if (!report) {
    return <DidomiSkeleton isLoading={true} />;
  }

  return (
    <div>
      {vendorsGraphNodes?.length && vendorsGraphEdges?.length && (
        <DidomiCardContainer className="relative mt-l mb-l" style={{ '--card-inner-padding': '0px' }}>
          <div className="w-full h-[500px] bg-white mt-m mb-[50px]">
            <div ref={graphContainerRef} className="" style={{ height: '100%', width: '100%' }}></div>
          </div>
          {
            <InitiatorDetailModal
              data-testid="initiator-detail-modal"
              isOpen={isOpen}
              report={report}
              vendorId={clickedVendorNodeId}
              onClose={handleClose}
              onVendorChange={handleVendorChange}
            />
          }
          <div className="mt-x">
            <div className="absolute top-[5px] left-[5px] w-[410px]" style={{ borderRadius: '8px', padding: '15px', background: 'rgba(249,250,250,0.7)' }}>
              <p className="font-semibold mb-xxs" style={{ fontSize: '13px' }}>
                Concatenation Graph (unknown vendors included)
              </p>
              <table className="border-collapse border border-slate-400" style={{ border: '1px solid #ccc' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ccc', padding: '5px' }} colSpan={10}></th>
                    <th style={{ border: '1px solid #ccc', padding: '5px', width: '110px', fontSize: '11px' }}>Not dropping trackers</th>
                    <th style={{ border: '1px solid #ccc', padding: '5px', width: '110px', fontSize: '11px' }}>Dropping trackers</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', fontSize: '11px' }} colSpan={10}>
                      Initiated by 3rd parties only
                    </td>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', width: '110px' }}>
                      <svg width="21" height="21" className="m-auto">
                        <rect x="0" y="0" width="21" height="21" stroke="black" fill="rgb(238, 128, 91)" strokeWidth="1" />
                      </svg>
                    </td>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', width: '110px' }}>
                      <svg width="25" height="21" className="m-auto" style={{ transform: 'scaleY(-1)' }}>
                        <polygon fill="rgb(238, 128, 91)" strokeWidth="1" stroke="black" points="0,0 25,0 12.5,21.65" />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', fontSize: '11px' }} colSpan={10}>
                      Initiated by both the property and by 3rd parties
                    </td>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', width: '110px' }}>
                      <svg width="21" height="21" className="m-auto">
                        <rect x="0" y="0" width="21" height="21" stroke="black" fill="rgb(245, 192, 90)" strokeWidth="1.5" />
                      </svg>
                    </td>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', width: '110px' }}>
                      <svg width="25" height="21" className="m-auto" style={{ transform: 'scaleY(-1)' }}>
                        <polygon fill="rgb(245, 192, 90)" strokeWidth="1" stroke="black" points="0,0 25,0 12.5,21.65" />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', fontSize: '11px' }} colSpan={10}>
                      Initiated by the property only
                    </td>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', width: '110px' }}>
                      <svg width="21" height="21" className="m-auto">
                        <rect x="0" y="0" width="21" height="21" stroke="black" fill="rgb(33, 198, 178)" strokeWidth="1.5" />
                      </svg>
                    </td>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', width: '110px' }}>
                      <svg width="25" height="21" className="m-auto" style={{ transform: 'scaleY(-1)' }}>
                        <polygon fill="rgb(33, 198, 178)" strokeWidth="1" stroke="black" points="0,0 25,0 12.5,21.65" />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div className="absolute top-[20px] right-[20px] w-[200px]">
                <DidomiSelect
                  label="Filter by scenario"
                  data-testid="scenario-filter"
                  value={selectedScenario}
                  onValueChange={event => setSelectedScenario(event.detail as ScenarioType)}
                >
                  <DidomiSelectOptions>
                    {availableScenarios.map(scenario => {
                      return <DidomiSelectOption key={scenario.value} value={scenario.value} label={scenario.label} />;
                    })}
                  </DidomiSelectOptions>
                </DidomiSelect>
              </div>
            </div>
          </div>
        </DidomiCardContainer>
      )}
    </div>
  );
};

export { ComplianceReportVendorGraph };
