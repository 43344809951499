import React from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiTableRow, DidomiTableTd } from '@didomi/ui-atoms-react';
import { Partner } from '@types';
import { getVendorNamespace } from '../../utils/getVendorNamespace.util';
import { TextWithHighlighterAndTooltip } from '../TextWithHighlighterAndTooltip/TextWithHighlighterAndTooltip';

interface Props {
  vendors: Partner[];
  search: string;
}

const EditVendorTemplateTableRow = ({ vendors, search }: Props) => {
  return (
    <>
      {vendors.map((vendor: Partner) => {
        return (
          <DidomiTableRow data-skeleton key={vendor.id} selectionValue={vendor.id}>
            <DidomiTableTd data-testid={`name-${vendor.name}`}>
              <TextWithHighlighterAndTooltip searchText={search} text={getTranslatedValue(vendor.name, '-')} />
            </DidomiTableTd>
            <DidomiTableTd data-testid={`id-${vendor.id}`}>
              <span>{vendor.id}</span>
            </DidomiTableTd>
            <DidomiTableTd data-testid={`sdk-id-${vendor.sdk_id}`}>
              <span>{vendor.sdk_id}</span>
            </DidomiTableTd>
            <DidomiTableTd>
              <span>{getVendorNamespace(vendor)}</span>
            </DidomiTableTd>
          </DidomiTableRow>
        );
      })}
    </>
  );
};

export { EditVendorTemplateTableRow };
