import { useMemo } from 'react';
import { Partner } from '@types';
import { isATPVendor, isCustomVendor, isIABVendor, isSelectedVendor } from './vendorTypes.util';
import { VendorFilterType } from '../enums/vendor-filter-type.enum';

/**
 * Filter the list of vendors based on the selected filter type (all, custom, IAB, or ATP).
 *
 * @param vendors - The list of vendors.
 * @param vendorsFilter - The filter type to apply.
 * @param selectedVendorsIds
 * @param organizationId - The current organization's ID.
 * @returns The filtered list of vendors.
 */
export const useFilteredVendors = (vendors: Partner[], vendorsFilter: string, selectedVendorsIds: string[], organizationId: string) => {
  return useMemo(() => {
    if (!vendors?.length) return [];

    switch (vendorsFilter) {
      case VendorFilterType.ALL:
        return vendors;
      case VendorFilterType.CUSTOM:
        return vendors.filter(vendor => isCustomVendor(vendor, organizationId));
      case VendorFilterType.IAB:
        return vendors.filter(isIABVendor);
      case VendorFilterType.ATP:
        return vendors.filter(isATPVendor);
      case VendorFilterType.SELECTED:
        return vendors.filter(vendor => isSelectedVendor(vendor, selectedVendorsIds));
      default:
        return vendors;
    }
  }, [vendors, vendorsFilter, organizationId, selectedVendorsIds]);
};
