import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { NoticeTemplate } from '../../../interfaces/notice-template.interface';

export const useNoticeTemplate = (id: string, options?: UseQueryOptions<NoticeTemplate, AxiosError>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<NoticeTemplate>(`${apiRoutes.noticeTemplates}/${id}`, {
      params: { organization_id: organizationId },
    });
    return response.data;
  };

  return useQuery<NoticeTemplate, AxiosError>(['notice-templates', { id, organization_id: organizationId }], fetch, {
    ...options,
  });
};
