import { useNavigate, useParams } from 'react-router-dom';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { filterSortPaginate } from '@didomi/helpers';
import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';
import {
  DidomiButton,
  DidomiChip,
  DidomiFiltersBar,
  DidomiHeaderInput,
  DidomiIcon,
  DidomiLoader,
  DidomiModal,
  DidomiModalContent,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableTh,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { useActiveOrganization, useFeatureFlag, useSnackbar, useSPARouter } from '@didomi/utility-react';
import { sanitizeHTML } from '@didomi/xss/sanitizers';
import { tx } from '@twind/core';
import { homeRoute, manageVendorTemplatesRoute } from '@constants';
import { useEditDomains, useProperties, useVendors } from '@hooks';
import { OrganizationProperty, SortConfig } from '@types';
import { ComplianceReportDetailTableEmptyRow } from '../../components/ComplianceReportDetail/ComplianceReportDetailTableEmptyRow';
import { EditVendorTemplateTableRow } from '../../components/ManageVendorTemplates/EditVendorTemplateTableRow';
import { VendorFilterType } from '../../enums/vendor-filter-type.enum';
import { useCreateNoticeTemplateVendors } from '../../hooks/data/notice-templates/useCreateNoticeTemplateVendors.hook';
import { useDeleteNoticeTemplateVendors } from '../../hooks/data/notice-templates/useDeleteNoticeTemplateVendors.hook';
import { useNoticeTemplate } from '../../hooks/data/notice-templates/useNoticeTemplate.hook';
import { useNoticeTemplateVendors } from '../../hooks/data/notice-templates/useNoticeTemplateVendors.hook';
import { usePatchNoticeTemplate } from '../../hooks/data/notice-templates/usePatchNoticeTemplate.hook';
import { usePaginationQueryParams } from '../../hooks/usePaginationQueryParams.hook';
import { ManageVendorTemplatesLayout } from '../../layouts/ManageVendorTemplatesLayout/ManageVendorTemplatesLayout';
import { BindPropertiesWithNoticeTemplateModal } from '../../modals/BindPropertiesWithNoticeTemplateModal/BindPropertiesWithNoticeTemplateModal';
import { useFilteredVendors } from '../../utils/useFilteredVendors.util';

type SortableFields = 'name' | 'id' | 'sdk_id' | 'namespace';

const SEARCH_FIELDS = [{ field: 'name' }];

const INITIAL_SORT: SortConfig<SortableFields> = { field: 'name', dir: 'asc' };

export const EditVendorTemplate = () => {
  const { organizationId } = useActiveOrganization();
  const { id: noticeTemplateId } = useParams<{ id: string }>();
  const { navigateTo } = useSPARouter();
  const navigate = useNavigate();
  const { displaySnackbar } = useSnackbar();

  const [isAcmVendorTemplatesEnabled] = useFeatureFlag('acm_vendor_templates');

  useLayoutEffect(() => {
    if (!isAcmVendorTemplatesEnabled) {
      navigate(homeRoute);
    }
  }, [isAcmVendorTemplatesEnabled, navigate]);

  const [selectedPropertyIds, setSelectedPropertyIds] = useState<string[]>([]);
  const [isOpenBindPropertiesWithNoticeTemplateModal, setIsOpenBindPropertiesWithNoticeTemplateModal] = useState(false);
  const [selectedPartnersIds, setSelectedPartnersIds] = useState<string[]>([]);
  const [noticeTemplateName, setNoticeTemplateName] = useState('');
  const [vendorsFilter, setVendorsFilter] = useState<VendorFilterType>(VendorFilterType.ALL);
  const [sortConfig, setSortConfig] = useState<SortConfig<SortableFields>>(INITIAL_SORT);
  const [propertiesThatBelongToTemplate, setPropertiesThatBelongToTemplate] = useState<string[]>([]);

  const { isLoading: isFetchingProperties, data: { data: properties } = {} } = useProperties({
    sort: { field: 'name', dir: 'asc' },
    limit: 1000,
    onSuccess: response => {
      if (response?.data) {
        const tempPropertiesThatBelongToTemplate: OrganizationProperty[] = response.data?.filter(property => property.notice_template_id === noticeTemplateId);
        setSelectedPropertyIds(tempPropertiesThatBelongToTemplate.map(property => property.id));
        setPropertiesThatBelongToTemplate(tempPropertiesThatBelongToTemplate.map(property => property.name));
      }
    },
  });
  const { isLoading: isFetchingNoticeTemplate } = useNoticeTemplate(noticeTemplateId, {
    refetchOnMount: 'always',
    onSuccess: noticeTemplate => {
      setNoticeTemplateName(noticeTemplate?.name);
    },
  });
  const {
    refetch: refetchNoticeTemplateVendors,
    isLoading: isFetchingNoticeTemplateVendors,
    data: { data: noticeTemplateVendors } = {},
  } = useNoticeTemplateVendors(noticeTemplateId, {
    refetchOnMount: 'always',
    onSuccess: response => {
      if (response?.data) {
        const noticeTemplateVendorIds: string[] = response?.data.map(noticeTemplateVendor => noticeTemplateVendor.metadata_partner_id);
        setSelectedPartnersIds(noticeTemplateVendorIds);
      }
    },
  });
  const { isLoading: isPatchingNoticeTemplate, mutateAsync: patchNoticeTemplate } = usePatchNoticeTemplate(noticeTemplateId);
  const { data: fetchedVendors = [], isLoading: isFetchingVendors } = useVendors();
  const { isLoading: isCreatingNoticeTemplateVendors, mutateAsync: createNoticeTemplateVendors } = useCreateNoticeTemplateVendors(noticeTemplateId);
  const { isLoading: isDeletingNoticeTemplateVendors, mutateAsync: deleteNoticeTemplateVendors } = useDeleteNoticeTemplateVendors(noticeTemplateId);
  const { mutateAsync: patchManyProperties, isLoading: isPatchingManyProperties } = useEditDomains();

  const filteredVendors = useFilteredVendors(fetchedVendors, vendorsFilter, selectedPartnersIds, organizationId);

  const { search, limit, page: currPage, setSearch, setLimit, setPage: setCurrPage } = usePaginationQueryParams({ defaultLimit: 20 });

  const { data: vendors, total } = useMemo(() => {
    return filterSortPaginate(filteredVendors, {
      search,
      searchFields: SEARCH_FIELDS,
      limit,
      page: currPage,
      sortConfig,
    });
  }, [currPage, limit, search, sortConfig, filteredVendors]);

  const setFirstPage = () => {
    setCurrPage(
      new CustomEvent<{ page: number }>('pageChange', {
        detail: {
          page: 1,
        },
      }),
    );
  };

  const vendorsFilters = useMemo(
    () => ({
      filterByVendor: {
        maxVisibleItems: 5,
        defaultValue: VendorFilterType.ALL,
        value: vendorsFilter,
        options: [
          { value: VendorFilterType.SELECTED, label: 'Selected vendors' },
          { value: VendorFilterType.ALL, label: 'All vendors' },
          { value: VendorFilterType.CUSTOM, label: 'Custom vendors' },
          { value: VendorFilterType.IAB, label: 'IAB vendors' },
          { value: VendorFilterType.ATP, label: 'Google ATP vendors' },
        ],
      },
    }),
    [vendorsFilter],
  );

  const handleSortChange = (e: CustomEvent) => {
    const newSortBy = e.detail.sortId;
    const newSortDir = newSortBy !== sortConfig.field ? 'desc' : e.detail.direction;
    setFirstPage();
    setSortConfig({ dir: newSortDir, field: newSortBy ?? sortConfig.field });
  };

  const handleRowSelectionChange = (event: CustomEvent) => {
    if (event.type === 'toggleAllRowsSelectedChange') {
      if (event.detail.selectedItems.length) {
        setSelectedPartnersIds(vendors.map(vendor => vendor.id));
      } else {
        setSelectedPartnersIds([]);
      }
    } else {
      setSelectedPartnersIds(event.detail.newSelectedItems);
    }
  };

  const handleFilterChange = (e: DidomiFiltersBarCustomEvent<{ key: string; newValue: any }>) => {
    const filterValue = e.detail.newValue;
    setVendorsFilter(filterValue);
  };

  const handleClearFilters = () => {
    setSearch('');
    setVendorsFilter(VendorFilterType.ALL);
  };

  const handleSave = async () => {
    displaySnackbar('Saving vendor template...');

    const initialNoticeTemplateVendorIds: string[] = noticeTemplateVendors.map(vendor => vendor.metadata_partner_id);
    const newSelectedTemplateVendorIds: string[] = selectedPartnersIds.filter(id => !initialNoticeTemplateVendorIds.includes(id));
    const removedTemplateVendorIds: string[] = initialNoticeTemplateVendorIds.filter(id => !selectedPartnersIds.includes(id));

    if (newSelectedTemplateVendorIds.length) {
      await createNoticeTemplateVendors(
        newSelectedTemplateVendorIds.map(vendorId => ({
          metadata_partner_id: vendorId,
        })),
      );
    }

    if (removedTemplateVendorIds.length) {
      await deleteNoticeTemplateVendors(
        removedTemplateVendorIds.map(vendorId => ({
          metadata_partner_id: vendorId,
        })),
      );
    }

    await patchNoticeTemplate({ name: noticeTemplateName });

    await refetchNoticeTemplateVendors();

    displaySnackbar('Saved vendor template!');
  };

  const handleAttachPropertiesSubmit = async (selectedPropertyIds: string[]) => {
    // TODO: Implement and replace with patchMany whenever got time.
    const initialPropertyIdsAttachedToNoticeTemplateId = properties.filter(property => property.notice_template_id === noticeTemplateId).map(property => property.id);

    const unselectedPropertyIds = initialPropertyIdsAttachedToNoticeTemplateId.filter(id => !selectedPropertyIds.includes(id));

    const propertiesToUpdate = [
      ...selectedPropertyIds.map((propertyId: string) => ({ notice_template_id: noticeTemplateId, id: propertyId })),
      ...unselectedPropertyIds.map((propertyId: string) => ({ notice_template_id: null, id: propertyId })),
    ];

    await patchManyProperties(propertiesToUpdate);

    setIsOpenBindPropertiesWithNoticeTemplateModal(false);

    displaySnackbar('Saved vendor template!');
  };

  const isPaginationEnabled = vendors.length > 0;

  const isLoading =
    isFetchingProperties ||
    isFetchingVendors ||
    isFetchingNoticeTemplate ||
    isPatchingNoticeTemplate ||
    isFetchingNoticeTemplateVendors ||
    isCreatingNoticeTemplateVendors ||
    isDeletingNoticeTemplateVendors ||
    isPatchingManyProperties;

  return (
    <DidomiSkeleton isLoading={isLoading} variant="layout">
      <ManageVendorTemplatesLayout
        backText={'Back to Manage Notice Templates'}
        backPath={manageVendorTemplatesRoute}
        fullWidth
        /* eslint-disable-next-line react/no-children-prop */
        children={
          <div className="py-6 flex flex-col">
            <div className="flex justify-between items-center gap-xxs mb-xxxs">
              <DidomiTooltip content={propertiesThatBelongToTemplate?.length > 0 ? propertiesThatBelongToTemplate?.join(', ') : 'No properties attached to this vendor template'}>
                <div className="text-primary-blue-5" data-skeleton>
                  {propertiesThatBelongToTemplate?.length > 0 ? (
                    <>
                      <DidomiChip label={String(propertiesThatBelongToTemplate?.length)} />
                      <span className="ml-xxxs">properties attached to this vendor template</span>
                    </>
                  ) : (
                    <span>No properties attached to this vendor template</span>
                  )}
                </div>
              </DidomiTooltip>

              <div>
                <DidomiButton
                  data-tracking="acm-redirect-data-manager"
                  data-cy="redirect-data-manager-button"
                  data-testid="redirect-data-manager-button"
                  onClick={() => navigateTo('/data-manager/vendors')}
                  variant="secondary"
                  size="small"
                  aria-label="Go to Data Manager"
                  className="ml-2"
                  data-skeleton
                >
                  Manage Vendors
                </DidomiButton>

                <DidomiButton
                  data-tracking="acm-attach-properties"
                  data-cy="attach-properties-button"
                  data-testid="attach-properties-button"
                  onClick={() => setIsOpenBindPropertiesWithNoticeTemplateModal(true)}
                  size="small"
                  aria-label="Attach properties"
                  className="ml-2"
                  data-skeleton
                >
                  Attach properties
                </DidomiButton>
              </div>
            </div>

            <DidomiFiltersBar
              data-testid="filter-bar"
              className="flex-1"
              filters={vendorsFilters}
              placeholderTextFilter="Search a vendor"
              showSearch
              searchValue={search}
              collapsibleFilters={false}
              onSearchTextChange={setSearch}
              onClearAllFilters={handleClearFilters}
              onFilterValueChange={handleFilterChange}
            />

            <DidomiTable
              sortable
              sortBy={sortConfig.field}
              sortDirection={sortConfig.dir}
              onSortChange={handleSortChange}
              selectable
              selectedItems={selectedPartnersIds}
              onToggleAllRowsSelectedChange={handleRowSelectionChange}
              onRowSelectionChange={handleRowSelectionChange}
              style={{ width: 'calc(100% - 2px)', margin: '16px auto' }}
            >
              <DidomiTableHeading>
                <DidomiTableHeaderRow>
                  <DidomiTableTh sortId="name">Name</DidomiTableTh>
                  <DidomiTableTh sortId="id">VENDOR ID (API)</DidomiTableTh>
                  <DidomiTableTh sortId="sdk_id">SDK ID</DidomiTableTh>
                  <DidomiTableTh>Namespace</DidomiTableTh>
                </DidomiTableHeaderRow>
              </DidomiTableHeading>
              <DidomiTableBody>
                {vendors.length > 0 && <EditVendorTemplateTableRow vendors={vendors} search={search} />}
                {!isLoading && vendors.length === 0 && <ComplianceReportDetailTableEmptyRow />}
              </DidomiTableBody>
            </DidomiTable>

            {isPaginationEnabled && (
              <DidomiPaginator
                className="self-end mt-xs w-full justify-end flex"
                variant="normal"
                page={currPage}
                itemCount={total}
                size={limit}
                onPageSizeChange={setLimit}
                onPageChange={setCurrPage}
              />
            )}

            <DidomiModal isOpen={isPatchingManyProperties || isDeletingNoticeTemplateVendors || isCreatingNoticeTemplateVendors || isPatchingNoticeTemplate}>
              <DidomiLoader></DidomiLoader>
              <DidomiModalContent className="mt-m">{'We are saving your vendor template ...'}</DidomiModalContent>
            </DidomiModal>

            {properties?.length && (
              <BindPropertiesWithNoticeTemplateModal
                properties={properties}
                selectedPropertyIds={selectedPropertyIds}
                noticeTemplateId={noticeTemplateId}
                isOpen={isOpenBindPropertiesWithNoticeTemplateModal}
                onOptionSelect={setSelectedPropertyIds}
                onClose={setIsOpenBindPropertiesWithNoticeTemplateModal}
                onSubmit={handleAttachPropertiesSubmit}
              />
            )}
          </div>
        }
        headerChildren={
          <div slot="header-title" data-testid="widget-tilte-section" className="flex items-center">
            <div className="flex justify-between items-center">
              <DidomiHeaderInput
                data-cy="notice-template-title"
                value={sanitizeHTML(noticeTemplateName).result}
                onValueChange={ev => {
                  setNoticeTemplateName(ev.detail);
                }}
                icon="edit"
                tooltipContent="Edit title"
                className={tx({ 'min-w-[250px] h-10': isLoading })}
                data-skeleton
              />
            </div>
          </div>
        }
        actionChildren={
          <div>
            <DidomiButton variant="top" data-testid="notice-template-save-button" onClick={handleSave}>
              Save as vendor template
              <DidomiIcon name="save" className="ml-2" aria-label="Save as vendor template" data-skeleton />
            </DidomiButton>
          </div>
        }
      ></ManageVendorTemplatesLayout>
    </DidomiSkeleton>
  );
};
