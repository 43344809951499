import React, { FunctionComponent } from 'react';
import { DidomiFeatureHeader, DidomiButton } from '@didomi/ui-atoms-react';
interface IMainLayoutProps {
  children: React.ReactChild;
  TitleAction?: FunctionComponent<any>;
  loading?: boolean;
  isQuotaExceeded?: boolean;
}
import { useSPARouter } from '@didomi/utility-react';
import { useAccessPolicies } from '@hooks';

/**
 * Main Layout
 * Default layout with header
 */
export const MainLayout = ({ children, TitleAction, isQuotaExceeded = false }: IMainLayoutProps): JSX.Element => {
  const { hasComplianceReportEditAccess } = useAccessPolicies();
  const { navigateTo } = useSPARouter();

  return (
    <section className="p-l pb-20 h-full box-border !flex flex-col">
      <div>
        <DidomiFeatureHeader title-text="Compliance reports">
          <div slot="description">
            Automatic monthly scans to illustrate key elements for GDPR compliance including a simple way to track vendors, trackers, and compliance gaps
          </div>
          <div slot="title-actions" data-skeleton>
            {TitleAction && <TitleAction />}
          </div>
          <div slot="actions" className="flex">
            {hasComplianceReportEditAccess && (
              <DidomiButton disabled={isQuotaExceeded} onClick={() => navigateTo('agnostik-compliance-report/add-domain')} slot="actions" className="mr-xs" data-cy="new-domain">
                Add Domain
              </DidomiButton>
            )}
          </div>
        </DidomiFeatureHeader>
      </div>
      {children}
    </section>
  );
};
