import { useMemo } from 'react';
import { useFeatureFlag } from '@didomi/utility-react';
import { useOrganizationScrapingQuotas } from './useOrganizationScrapingQuotas.hook';
import { SCRAPER_ENABLED_PROPERTIES_QUOTA } from '../consts';

type UseVerifyScrapingQuotaOptions = {
  totalProperties: number;
};

export const useVerifyScrapingQuota = ({ totalProperties }: UseVerifyScrapingQuotaOptions) => {
  // fetch organization scraping quota data
  const { data: organizationScrapingQuota, isLoading } = useOrganizationScrapingQuotas();

  // check if the compliance report premium feature is enabled
  const [hasComplianceReportPremiumFeature] = useFeatureFlag('compliance_report_v2');

  // determine the quota limit or fallback to 250 as default
  const organizationQuota = organizationScrapingQuota?.scraper_enabled_properties ?? SCRAPER_ENABLED_PROPERTIES_QUOTA;

  // determine if the quota limit has been exceeded
  const isQuotaExceeded = useMemo(
    () => !hasComplianceReportPremiumFeature && totalProperties >= organizationQuota,
    [totalProperties, organizationQuota, hasComplianceReportPremiumFeature],
  );

  return {
    isLoading,
    isQuotaExceeded,
    organizationQuota,
  };
};
