import React from 'react';
import { DidomiButton, DidomiModal, DidomiModalHeader, DidomiModalActions } from '@didomi/ui-atoms-react';

type DeleteNoticeTemplateModalProps = {
  isOpen?: boolean;
  onCancel?: () => void;
  onProceed?: () => void;
};

const DeleteNoticeTemplateModal = ({ isOpen, onCancel, onProceed }: DeleteNoticeTemplateModalProps): JSX.Element => {
  return (
    <DidomiModal isOpen={isOpen} permanent="true">
      <DidomiModalHeader
        illustrationName="warning-modal"
        modalSubTitle="Please pay attention"
        modalDescription="Are you sure you want to delete this vendor template? This action is permanent and may impact your configuration."
        modalDescriptionSpace="none"
      ></DidomiModalHeader>

      <DidomiModalActions>
        <DidomiButton variant="secondary" onClick={onCancel}>
          Oops, no thanks
        </DidomiButton>
        <DidomiButton data-tracking="acm-delete-notice-template" onClick={onProceed}>
          Yes, delete
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};

export { DeleteNoticeTemplateModal };
