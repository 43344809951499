import { Partner } from '@types';
import { isATPVendor, isIABVendor } from './vendorTypes.util';

export const getVendorNamespace = (partner: Partner) => {
  if (isIABVendor(partner)) {
    return 'IAB';
  }

  if (isATPVendor(partner)) {
    return 'Google ATP';
  }

  return 'Custom';
};
