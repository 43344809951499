import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { DidomiHintbox, DidomiCardContainer, DidomiIcon, DidomiCollapse, DidomiButton } from '@didomi/ui-atoms-react';
import { ComplianceReportCoreTrackerTableOld, ComplianceReportCoreVendorTableOld } from '@components';
import { AggregatedVendor, Report, PropertyTrackerItem, Purpose } from '@types';
import { aggregateComplianceIssues } from '@utils';

interface Props {
  report: Report;
  isLoadingNodes?: boolean;
  nodes?: any;
  purposes?: Purpose[];
  propertyTrackerSettings?: PropertyTrackerItem[];
  isLegacy: boolean;
}
const ComplianceReportDetailComplianceIssues = ({ report, isLoadingNodes, nodes, purposes = [], propertyTrackerSettings = [], isLegacy }: Props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [expandedTable, setExpandedTable] = useState({
    lifetimeTrackers: false,
    nonAdequateVendors: false,
    lowIndexes: false,
    unknowVendors: false,
  });

  const complianceIssues = useMemo(() => {
    return aggregateComplianceIssues(report, purposes, propertyTrackerSettings);
  }, [report, purposes, propertyTrackerSettings]);

  const handleCollapseClick = (id, val) => {
    const clicked = {};
    clicked[id] = val;
    setExpandedTable(old => ({
      ...old,
      ...clicked,
    }));
  };
  const handleMatchVendors = () => {
    // TODO REMOVE legacy check after migration
    const params = new URLSearchParams(searchParams);

    const legacyPath = isLegacy ? 'legacy/' : '';
    if (isLegacy) {
      params.set('report_provider', 'legacy');
    }
    const queryString = params.toString() ? `?${params.toString()}` : '';
    navigate(`match-unknown-domains/${legacyPath}${report.id}${queryString}`);
  };

  return (
    <div>
      {complianceIssues.length === 0 && <DidomiHintbox data-skeleton title-text="We found 0 compliance issues" icon-name="success" variant="success"></DidomiHintbox>}
      {complianceIssues.length > 0 && (
        <>
          <DidomiHintbox
            data-skeleton
            title-text={`We found ${complianceIssues.length} compliance issues`}
            icon-name="warning-blue"
            variant="neutral"
            data-cy="hintbox-issue"
          ></DidomiHintbox>
          {complianceIssues.map(issue => {
            return issue.type === 'info' ? (
              <DidomiCardContainer style={{ '--card-inner-padding': '16px', '--card-border': '2px solid #265973', '--card-outer-bg:': 'transparent' }} className="mt-xs">
                <div className="flex flex-row items-center">
                  <DidomiIcon className="w-[15px] mr-xs" name={issue.warningLevel === 'alert' ? 'failure' : 'warning'}></DidomiIcon>
                  <span className="text-primary-blue-6">{issue.name}</span>
                </div>
              </DidomiCardContainer>
            ) : issue.type === 'tracker-table' ? (
              <DidomiCardContainer
                id="panel-1-toggle"
                style={{ '--card-inner-padding': '16px', '--card-border': '2px solid #265973', '--card-outer-bg:': 'transparent' }}
                className="mt-xs items-center w-full"
              >
                <div className="flex flex-row items-center">
                  <DidomiIcon className="w-[15px] mr-xs" name="warning"></DidomiIcon>
                  <li
                    role="menuitem"
                    onClick={() => handleCollapseClick(issue.expandId, !expandedTable[issue.expandId])}
                    className="mr-xs w-full cursor-pointer flex text-primary-blue-6"
                  >
                    <p className="underline underline-offset-4 mr-[4px] text-primary-blue-6">{issue.entities}</p>
                    {issue.name}{' '}
                    {issue.extraLink && (
                      <a className="ml-xxs text-underline text-primary-blue-4" href={issue.extraLink} target="_blank" rel="noreferrer">
                        {issue.extralinkText}
                      </a>
                    )}
                  </li>
                  {!expandedTable[issue.expandId] && (
                    <DidomiIcon name="down-on" className="ml-auto cursor-pointer" onClick={() => handleCollapseClick(issue.expandId, !expandedTable[issue.expandId])} />
                  )}
                  {expandedTable[issue.expandId] && (
                    <DidomiIcon name="up-on" className="ml-auto cursor-pointer" onClick={() => handleCollapseClick(issue.expandId, !expandedTable[issue.expandId])} />
                  )}
                </div>

                <DidomiCollapse id="panel-1" is-expanded={expandedTable[issue.expandId]} className="w-full">
                  <div className="w-full">
                    <ComplianceReportCoreTrackerTableOld
                      forceTrackers={issue.dataTable as any[]}
                      report={report}
                      purposes={purposes}
                      propertyTrackerSettings={propertyTrackerSettings}
                      hideTableLabel
                      isLoadingNodes={isLoadingNodes}
                      nodes={nodes}
                    />
                  </div>
                </DidomiCollapse>
              </DidomiCardContainer>
            ) : (
              <DidomiCardContainer
                id="panel-1-toggle"
                style={{ '--card-inner-padding': '16px', '--card-border': '2px solid #265973', '--card-outer-bg:': 'transparent' }}
                className="mt-xs items-center w-full"
              >
                <div className="flex flex-row items-center">
                  <DidomiIcon className="w-[15px] mr-xs" name="warning"></DidomiIcon>
                  <li
                    role="menuitem"
                    onClick={() => handleCollapseClick(issue.expandId, !expandedTable[issue.expandId])}
                    className="mr-xs w-full cursor-pointer flex text-primary-blue-6"
                  >
                    <p className="underline underline-offset-4 mr-[4px] text-primary-blue-6">{issue.entities}</p>
                    {issue.name}
                  </li>
                  {!expandedTable[issue.expandId] && (
                    <DidomiIcon name="down-on" className="ml-auto cursor-pointer" onClick={() => handleCollapseClick(issue.expandId, !expandedTable[issue.expandId])} />
                  )}
                  {expandedTable[issue.expandId] && (
                    <DidomiIcon name="up-on" className="ml-auto cursor-pointer" onClick={() => handleCollapseClick(issue.expandId, !expandedTable[issue.expandId])} />
                  )}
                </div>

                <DidomiCollapse id="panel-1" isExpanded={expandedTable[issue.expandId]} className="w-full">
                  <div className="w-full">
                    {issue.dataTable.some(vendor => vendor.isUnknow === true) && (
                      <div className=" flex items-center bg-marketplace-promotion  rounded-lg p-s my-xs">
                        <div className="flex items-center justify-between w-full">
                          <img alt="subtract-logo" className="w-[46px]" src={require('./../../assets/subtract.png')} />
                          <span className=" text-white px-xs">
                            In order to avoid such issues in future reports and increase their accuracy, please manually match the unmatched vendors.
                          </span>
                          <DidomiButton variant="secondary-light" size="small" className="ml-auto" onClick={handleMatchVendors}>
                            Match Vendors
                          </DidomiButton>
                        </div>
                      </div>
                    )}
                    <ComplianceReportCoreVendorTableOld
                      forceVendors={issue.dataTable as AggregatedVendor[]}
                      report={report}
                      hideTableLabel
                      hideTableFilter
                      displayOnlyName
                      isLoadingNodes={isLoadingNodes}
                      nodes={nodes}
                    />
                  </div>
                </DidomiCollapse>
              </DidomiCardContainer>
            );
          })}
        </>
      )}
    </div>
  );
};

export { ComplianceReportDetailComplianceIssues };
