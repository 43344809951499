import React from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiTableRow, DidomiTableTd, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { TRANSLATED_SCENARIOS_MAP } from '@constants';
import { ScenarioType } from '@enums';
import { AggregatedTracker, InitiatorsVendor } from '@types';
import { getTrackerTypeValue, getRanScenarios } from '@utils';
import { TruncatedTextWithTooltip } from '../TruncatedTextWithTooltip/TruncatedTextWithTooltip';

interface Props {
  trackers: AggregatedTracker[];
  onVendorClick: (vendor: string) => void;
  isLoadingNodes: boolean;
  isLoadingCookiePoliciesInfo?: boolean;
  nodes: any;
}
export const ComplianceReportDetailTableTrackerRow = ({ trackers = [], onVendorClick, isLoadingNodes, nodes, isLoadingCookiePoliciesInfo = false }: Props) => {
  return (
    <>
      {trackers.map((tracker, index) => (
        <DidomiTableRow data-skeleton key={`tracker-${tracker.label}-${index}`}>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={tracker.name} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={tracker.domain} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={tracker.party} />
          </DidomiTableTd>
          <DidomiTableTd>
            <button
              data-testid="initiator-button"
              className={`underline underline-offset-2 outline-none text-left contents ${isLoadingNodes && 'cursor-wait'}`}
              title={isLoadingNodes ? 'Processing associations...' : undefined}
              onClick={() => !isLoadingNodes && onVendorClick((tracker.initiator as any as InitiatorsVendor)?.identifier)}
            >
              <TruncatedTextWithTooltip value={(tracker.initiator as any as InitiatorsVendor)?.label} className="underline underline-offset-2" />
            </button>
          </DidomiTableTd>
          <DidomiTableTd>
            <button
              data-testid="vendor-button"
              className={`outline-none text-left contents ${isLoadingNodes && 'cursor-wait'}`}
              title={isLoadingNodes ? 'Processing associations...' : undefined}
              onClick={() =>
                !isLoadingNodes && nodes?.[(tracker.vendor as any as InitiatorsVendor)?.identifier] && onVendorClick((tracker.vendor as any as InitiatorsVendor)?.identifier)
              }
            >
              <TruncatedTextWithTooltip value={(tracker.vendor as any as InitiatorsVendor)?.label} className="underline underline-offset-2" />
            </button>
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={getTrackerTypeValue(tracker.type)} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={tracker.lifetime} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={TRANSLATED_SCENARIOS_MAP[tracker.functional_scenario]} />
          </DidomiTableTd>
          <DidomiTableTd>
            <TruncatedTextWithTooltip value={getRanScenarios(tracker.ran_scenarios as any as ScenarioType[])} />
          </DidomiTableTd>
          <DidomiTableTd>
            <div>
              {isLoadingCookiePoliciesInfo ? (
                <DidomiSkeleton data-testid="purpose-skeleton" isLoading={isLoadingCookiePoliciesInfo} style={{ height: '20px', width: '100px' }} />
              ) : (
                <TruncatedTextWithTooltip value={tracker?.purposes?.length > 0 ? tracker?.purposes.map(purpose => getTranslatedValue(purpose.description)).join(', ') : 'None'} />
              )}
            </div>
          </DidomiTableTd>
          <DidomiTableTd>
            <div>
              {isLoadingCookiePoliciesInfo ? (
                <DidomiSkeleton data-testid="exemption-status-skeleton" isLoading={isLoadingCookiePoliciesInfo} style={{ height: '20px', width: '100px' }} />
              ) : (
                <span className={`inline-block truncate w-full py-1`}>{tracker.is_exempt ? 'Yes' : 'No'}</span>
              )}
            </div>
          </DidomiTableTd>
        </DidomiTableRow>
      ))}
    </>
  );
};
